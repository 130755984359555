import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
	'inline-flex items-center justify-center whitespace-nowrap rounded-[10px] text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
	{
		variants: {
			variant: {
				default: 'bg-secondary text-primary-900 rounded-[10px] font-bold',
				primary: 'bg-primary-200 text-white font-bold rounded-[10px]',
				ghost: 'text-secondary font-bold',
				ghostLight: 'text-white',
			},
			size: {
				default: 'h-10 px-4 py-2',
				sm: 'h-9 rounded-[10px] px-3',
				lg: 'h-11 rounded-[10px] px-8',
				icon: 'h-10 w-10',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
	loading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, loading, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button'
		return loading ? (
			<Comp
				disabled={true}
				className={
					cn(buttonVariants({ variant, size, className })) +
					'flex items-center gap-2 w-full'
				}
				ref={ref}
				{...props}
			>
				<svg
					className="h-4 w-4 animate-spin text-white"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle
						className="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="#fff"
						strokeWidth="4"
					/>
					<path
						className="opacity-75"
						fill="#fff"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
					/>
				</svg>
			</Comp>
		) : (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			/>
		)
	},
)
Button.displayName = 'Button'

export { Button, buttonVariants }
