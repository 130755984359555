import { IQuestion } from '@/classes/questions/interface/question'
import { Button } from '@/components/ui/button'
import { useQuestionContext } from '@/contexts/QuestionContext'
import { useHistory } from 'react-router'

export default function DestinyTouch({
	question,
}: {
	question: IQuestion
}) {
	const {
		hook: { setCurrentQuestion },
	} = useQuestionContext()

	const navigate = useHistory()

	return question ? (
		<div className="bg-primary-300 flex flex-col justify-between py-2 px-4 rounded-[10px] gap-2 lg:h-full lg:gap-8">
			<div className="flex flex-col gap-2">
				<div className="flex items-start justify-between">
					<p className="text-secondary font-bold text-small w-full lg:text-[1rem]">
						{question.destinyTouch ? 'Toque do destino' : question.question}
					</p>
					<p className="text-primary-100 text-extraSmall w-full text-end">
						{question.createdAt}
					</p>
				</div>
				<div className="flex flex-col gap-2">
					<p className="text-white text-extraSmall lg:text-small">
						{question?.kativo4}
					</p>
					<p className="text-white text-extraSmall lg:text-small">
						{question?.kativo5}
					</p>
					<p className="text-white text-extraSmall lg:text-small">
						{question?.kativo6}
					</p>
				</div>
			</div>
			<div className="flex items-center justify-end lg:justify-center lg:w-full">
				<Button
					onClick={() => {
						setCurrentQuestion(question)
						navigate.push('/details')
					}}
					variant="ghostLight"
					className="lg:hidden block font-bold p-0"
				>
					Ler completo.
				</Button>
				<Button
					onClick={() => {
						setCurrentQuestion(question)
						navigate.push('/details')
					}}
					variant="default"
					className="lg:block hidden font-bold px-8"
				>
					Ler completo.
				</Button>
			</div>
		</div>
	) : (
		<></>
	)
}
