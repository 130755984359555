import { stripePromise } from '@/App'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { InAppBrowser } from '@capgo/inappbrowser'
import axios from 'axios'
import UserEntity from '../../UserClass'
import { PromotionsManager } from '../timeLimitedPromotions/use'
import { setUser } from '../userData/setUser'

const getSuccessUrl = () => {
	if (Capacitor.getPlatform() === 'ios') {
		return `${process.env.REACT_APP_BACKEND_URL}/home`
	} else if (Capacitor.getPlatform() === 'android') {
		return `${process.env.REACT_APP_BACKEND_URL}/home`
	} else {
		;`${process.env.REACT_APP_CLIENT_URL}/`
	}
}

const getCancelUrl = () => {
	if (Capacitor.getPlatform() === 'ios') {
		return `${process.env.REACT_APP_BACKEND_URL}/shop`
	} else if (Capacitor.getPlatform() === 'android') {
		return `${process.env.REACT_APP_BACKEND_URL}/shop`
	} else {
		;`${process.env.REACT_APP_CLIENT_URL}/shop`
	}
}

export const handleBuyMix = async ({
	setLoading,
	data,
	userId,
	firstBuy,
	promotionalBuy = false,
	promotionCode,
	userEntity,
}: {
	setLoading: (loading: boolean) => void
	data: {
		coins: number
		touch: number
		priceId: string
	}
	userId: string
	firstBuy: boolean
	promotionalBuy?: boolean
	promotionCode?: string
	userEntity: UserEntity
}) => {
	setLoading(true)

	try {
		// Determine URLs based on platform
		const successUrl = getSuccessUrl()

		const cancelUrl = getCancelUrl()

		// Send the URLs to the backend when creating the checkout session
		const response = await axios.post(
			`${process.env.REACT_APP_BACKEND_URL}/payments/checkout`,
			{
				...data,
				userId,
				successUrl, // Pass the platform-specific success URL
				cancelUrl, // Pass the platform-specific cancel URL
				firstBuy,
				promotionalBuy,
				promotionCode,
			},
		)

		const { sessionId, url } = response.data
		const stripe = await stripePromise

		// Detect if running on mobile or web
		if (Capacitor.isNativePlatform()) {
			// Mobile environment: Use Capacitor Browser
			if (Capacitor.getPlatform() === 'ios') {
				await InAppBrowser.open({
					url: url,
				})

				// Listen for browser close
				InAppBrowser.addListener('urlChangeEvent', () => {
					setLoading(false)
					setUser(userEntity).then((res) => {
						PromotionsManager(userEntity, res)
					})
					// Optionally handle the user closing the browser (e.g., cancellation).
				})
			} else if (Capacitor.getPlatform() === 'android') {
				await Browser.open({ url })

				// Listen for browser close
				Browser.addListener('browserFinished', () => {
					console.log('Browser closed.')
					setLoading(false)
					setUser(userEntity).then((res) => {
						PromotionsManager(userEntity, res)
					})
				})
			}
		} else {
			// Web environment: Use Stripe's redirectToCheckout
			const { error } = await stripe!.redirectToCheckout({
				sessionId: sessionId,
			})

			if (error) {
				console.error('Stripe Checkout Error:', error)
			}
		}
	} catch (error) {
		console.error('Error in subscription:', error)
		setLoading(false)
	}
}
