import { Capacitor } from '@capacitor/core'
import { getApps, initializeApp } from 'firebase/app'
import {
	getAuth,
	indexedDBLocalPersistence,
	initializeAuth,
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const FirebaseCredentials = {
	apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app =
	getApps().length === 0 ? initializeApp(FirebaseCredentials) : getApps()[0]

Capacitor.isNativePlatform() &&
	initializeAuth(app, {
		persistence: indexedDBLocalPersistence,
	})

const db = getFirestore(app)
const auth = getAuth(app)

export { app, auth, db }
