import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { DeleteFormSchema, DeleteSchema } from './schema'

export default function DeleteForm() {
	const formMethods = useForm<DeleteFormSchema>({
		resolver: zodResolver(DeleteSchema),
	})

	return {
		formMethods,
	}
}
