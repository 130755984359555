import UserEntity from '@/classes/user/UserClass'
import { initMessaging } from '@/classes/user/actions/messaging/initMessaging'
import { PromotionsManager } from '@/classes/user/actions/timeLimitedPromotions/use'
import { setUser } from '@/classes/user/actions/userData/setUser'
import React, { useContext, useEffect } from 'react'

const UserContext = React.createContext({} as UserEntity)

export function UserContextProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const entity = new UserEntity()

	useEffect(() => {
		if (!entity.hook.firebaseUser) return
		setUser(entity).then(async (user) => {
			initMessaging(entity)
			PromotionsManager(entity, user)
		})
	}, [entity.hook.firebaseUser])

	return <UserContext.Provider value={entity}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
	const context = useContext(UserContext)

	return context
}
