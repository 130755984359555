import * as React from 'react'

import { cn } from '@/lib/utils'
import { Label } from './label'

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, label, ...props }, ref) => {
		return (
			<div className="flex flex-col gap-2 w-full">
				{label && (
					<Label className="text-white" htmlFor={props.id}>
						{label}
					</Label>
				)}
				<input
					type={type}
					className={cn(
						'flex h-10 w-full rounded-[10px] border-2 border-secondary bg-primary-300 px-3 py-2 text-sm ring-offset-secondary file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-slate-950 placeholder:text-primary-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-secondary-22 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-white',
						className,
					)}
					ref={ref}
					{...props}
				/>
			</div>
		)
	},
)
Input.displayName = 'Input'

export { Input }
