import { IQuestion } from '@/classes/questions/interface/question'
import { CardsData0, CardsData1, CardsData2 } from '@/data/cards/CardsData'

function formatSentences(...sentences: any[]) {
	return sentences
		.map((sentence, index) => {
			// Se a frase anterior não termina com espaço ou a frase atual não começa com espaço
			// adiciona um espaço entre elas
			if (
				index > 0 &&
				!sentences[index - 1].endsWith(' ') &&
				!sentence.startsWith(' ')
			) {
				return ' ' + sentence
			}
			return sentence
		})
		.join('')
}

export const renderFormattedSentences = (
	field:
		| 'kassivo1'
		| 'kassivo2'
		| 'kassivo3'
		| 'kativo4'
		| 'kativo5'
		| 'kativo6',
	firstNumber: number,
	secondNumber: number,
	thirdNumber: number,
) => {
	return formatSentences(
		CardsData0[firstNumber][field],
		CardsData1[secondNumber][field]?.toLowerCase(),
		CardsData2[thirdNumber][field]?.toLowerCase(),
	)
}

export function groupQuestionsByDate(questions: IQuestion[]) {
	return questions.reduce((acc: any, question) => {
		// Extract the date part (dd/mm/yyyy) from createdAt
		const date = question.createdAt.split(',')[0] // "23/09/2024"

		// Initialize the array for this date if it doesn't exist
		if (!acc[date]) {
			acc[date] = []
		}

		// Push the current question into the array for that date
		acc[date].push(question)

		return acc
	}, {})
}
