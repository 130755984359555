import { useMediaQuery } from 'usehooks-ts'
import DetailsDesktop from './DetailsDesktop'
import DetailsMobile from './DetailsMobile'

export default function Details() {
	const isLg = useMediaQuery('(min-width: 1024px)')

	return (
		<>
			{isLg && <DetailsDesktop />}
			{!isLg && <DetailsMobile />}
		</>
	)
}
