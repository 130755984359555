import { keys } from '@/configs/keys'
import UserEntity from '../../UserClass'

export const setUser = async (entity: UserEntity) => {
	return await entity
		.setClassById({
			id: entity.hook.firebaseUser?.uid || '',
			shouldUpdate: true,
			cachePath: keys.currentUser,
		})
		.then((res) => {
			entity.hook.setUser(res)
			return res
		})
}
