export const MESSAGING_TOPICS = [
	{
		topic: 'general',
		translate: 'Geral',
	},
	{
		topic: 'telegram_live',
		translate: 'Telegram Live',
	},
]
