import UserEntity from '../../UserClass'
import { setUser } from '../userData/setUser'

export const useCoins = async (entity: UserEntity, coinsUsed: number) => {
	await entity.update({
		id: entity.hook.firebaseUser?.uid,
		coins: entity.hook.user.coins - coinsUsed,
	})
	await setUser(entity)
}
