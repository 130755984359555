import { makeQuestion } from '@/classes/questions/actions/makeQuestion'
import { setQuestions } from '@/classes/questions/actions/setQuestions'
import { IQuestion } from '@/classes/questions/interface/question'
import { useCoins } from '@/classes/user/actions/spent/useCoins'
import { useTouch } from '@/classes/user/actions/spent/useTouch'
import { useQuestionContext } from '@/contexts/QuestionContext'
import { useUserContext } from '@/contexts/UserContext'
import { useHistory } from 'react-router'

export default function useConsultationHook() {
	const UserEntity = useUserContext()
	const {
		hook: { firebaseUser },
	} = UserEntity

	const questionEntity = useQuestionContext()
	const {
		hook: { setCurrentQuestion },
	} = questionEntity

	const navigate = useHistory()

	const handleMakeQuestion = async (question: string, isFree = false) => {
		const data = await makeQuestion(firebaseUser?.uid || '', questionEntity, {
			question,
			destinyTouch: false,
		})
		await postAction(data, isFree)
	}

	const handleDestinyTouch = async (isFree = false) => {
		const data = await makeQuestion(firebaseUser?.uid || '', questionEntity, {
			question: '',
			destinyTouch: true,
		})
		await postAction(data, isFree)
	}

	const postAction = async (data: IQuestion, isFree: boolean) => {
		if (!isFree) {
			if (data.destinyTouch) await useTouch(UserEntity, 1)
			else await useCoins(UserEntity, 1)
		}
		navigate.push('/details')
		setCurrentQuestion(data)
		setQuestions(questionEntity, firebaseUser?.uid || '')
	}

	return {
		handleMakeQuestion,
		handleDestinyTouch,
	}
}
