import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { forgotPassword } from '@/firebase/auth'
import SignUpForm from './form/form'
import { ForgotFormSchema } from './form/schema'

export default function Forgot({
	setPage,
}: {
	setPage: (page: 'signIn' | 'signUp' | 'forgot') => void
}) {
	const { formMethods } = SignUpForm()

	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods

	const submit = async (formData: ForgotFormSchema) => {
		await forgotPassword(formData.email)
		setPage('signIn')
	}

	return (
		<div className="flex flex-col items-center gap-4">
			<p className="text-white font-bold">Esqueci minha senha:</p>
			<form
				className="w-full xl:w-1/3 flex flex-col gap-6"
				onSubmit={handleSubmit(submit)}
			>
				<Input
					label="Email"
					{...register('email')}
					placeholder="johndoe@gmail.com"
				/>
				<Button type="submit" loading={isSubmitting} className="w-full">
					Enviar solicitação de troca de senha
				</Button>
			</form>
			<div className="flex items-end h-1/3 justify-center w-full">
				<Button
					className="font-bold"
					disabled={isSubmitting}
					onClick={() => {
						setPage('signIn')
					}}
					variant="ghostLight"
				>
					Voltar
				</Button>
			</div>
			<p className="text-white text-small text-center mb-1">
				Se o e-mail acima estiver associado à uma conta, você receberá um link
				para alteração da sua senha de segurança. <br />
				<br />
				Caso não tenha solicitado, desconsidere.
			</p>
		</div>
	)
}
