import { CardsType } from './CardsType'

export const CardsData0: CardsType[] = [
	{
		name: 'Sol',
		luckyNumber: 0,
		image: '/cards/r01_sol.jpg',
		kassivo1: 'A criação d',
		kassivo2: 'Ganhar o respeito por meio ou d',
		kassivo3: 'Coisas trazidas à vida ou a luz ',
		kativo4: 'Demonstrar ',
		kativo5: 'Realizar ',
		kativo6: 'Agir como líder ',
	},
	{
		name: 'Lua',
		luckyNumber: 0,
		image: '/cards/r02_lua.jpg',
		kassivo1: 'Os cuidados d',
		kassivo2: 'Sensações causadas por ',
		kassivo3: 'Reações ',
		kativo4: 'Defender ',
		kativo5: 'Aceitar ',
		kativo6: 'Deixar que suas sensações digam como ',
	},
	{
		name: 'Marte',
		luckyNumber: 0,
		image: '/cards/r03_marte.jpg',
		kassivo1: 'O impulso d',
		kassivo2: 'O confronto d',
		kassivo3: 'Ação ',
		kativo4: 'Energizar ',
		kativo5: 'Confrontar ',
		kativo6: 'Force você a ',
	},
	{
		name: 'Vênus',
		luckyNumber: 0,
		image: '/cards/r04_venus.jpg',
		kassivo1: 'A atração d',
		kassivo2: 'Prazeres d',
		kassivo3: 'Bons tempos, ',
		kativo4: 'Apreciar ',
		kativo5: 'Divertir-se ',
		kativo6: 'O encanto, a arte e a beleza são o caminho para ',
	},
	{
		name: 'Mercúrio',
		luckyNumber: 0,
		image: '/cards/r05_mercurio.jpg',
		kassivo1: 'O conhecimento d',
		kassivo2: 'Muitos pensamentos sobre ',
		kassivo3: 'Muitas palavras ',
		kativo4: 'Comunicar-se ',
		kativo5: 'Analisar ',
		kativo6: 'Deixe que sua mente lhe diga como ',
	},
	{
		name: 'Urano',
		luckyNumber: 0,
		image: '/cards/r06_urano.jpg',
		kassivo1: 'Um exemplo incomun d',
		kassivo2: 'Uma maneira diferente de encarar ',
		kassivo3: 'Uma mudança inesperada ',
		kativo4: 'Agite a situação usando ',
		kativo5: 'Seja receptivo a novas ideias sobre ',
		kativo6: 'Invente uma maneira para ',
	},
	{
		name: 'Saturno',
		luckyNumber: 0,
		image: '/cards/r07_saturno.jpg',
		kassivo1: 'A maturidade que traz ',
		kassivo2: 'Cuidado com ',
		kassivo3: 'Limites impostos ',
		kativo4: 'Seja realista a respeito d',
		kativo5: 'Preocupe-se quanto a ',
		kativo6: 'Você deve esperar antes de poder ',
	},
	{
		name: 'Júpter',
		luckyNumber: 0,
		image: '/cards/r08_jupter.jpg',
		kassivo1: 'A concessão d',
		kassivo2: 'Bençãos d',
		kassivo3: 'Boa sorte, ',
		kativo4: 'Aprender ou ensinar ',
		kativo5: 'Seja positivo a respeito d',
		kativo6: 'Crescer e expandir ',
	},
	{
		name: 'Netuno',
		luckyNumber: 0,
		image: '/cards/r09_netuno.jpg',
		kassivo1: 'A idealização d',
		kassivo2: 'Confusão a respeito d',
		kassivo3: 'Sacrifício ',
		kativo4: 'Inspire os outros mediante ',
		kativo5: 'Esteja preparado para o sacrifício por ',
		kativo6: 'A docilidade é o único caminho para ',
	},
	{
		name: 'Plutão',
		luckyNumber: 0,
		image: '/cards/r10_plutao.jpg',
		kassivo1: 'A necessidade de controlar ',
		kassivo2: 'Uma obssessão por ',
		kassivo3: 'A luta pelo poder ',
		kativo4: 'Renascer ',
		kativo5: 'Ir ao âmago d',
		kativo6: 'Faça ou morra! Você deve fazer ',
	},
	{
		name: 'O Nodo Norte',
		luckyNumber: 0,
		image: '/cards/r11_norte.jpg',
		kassivo1: 'Haverá ',
		kassivo2: 'Sucesso, ',
		kassivo3: 'Benefício, ',
		kativo4: 'Tenha fé em ',
		kativo5: 'Não se preocupe ',
		kativo6: 'Agora é o momento ',
	},
	{
		name: 'O Nodo Sul',
		luckyNumber: 0,
		image: '/cards/r12_sul.jpg',
		kassivo1: 'Não acontecerá ',
		kassivo2: 'Ansiedade sobre ',
		kassivo3: 'Problema ',
		kativo4: 'Lembrar ',
		kativo5: 'Reconsiderar ',
		kativo6: 'Agora não é o momento para ',
	},
]

export const CardsData1: CardsType[] = [
	{
		name: 'Áries',
		luckyNumber: 0,
		image: '/cards/s01_aries.jpg',
		kassivo1: 'Energia para enfrentar o desafio d',
		kassivo2: 'Seus desejos em relação a ',
		kassivo3: 'Resultado da honestidade e do esforço d',
		kativo4: 'Sua força de vontade ',
		kativo5: 'O que você pensa que sabe a respeito ',
		kativo6: 'Aja da sua maneira e ',
	},
	{
		name: 'Touro',
		luckyNumber: 0,
		image: '/cards/s02_touro.jpg',
		kassivo1: 'Fontes a manter ',
		kassivo2: 'A praticabilidade d',
		kassivo3: 'Resultado da produtividade d',
		kativo4: 'Suas crenças ',
		kativo5: 'Os custos d',
		kativo6: 'Use a maneira mais correta e ',
	},
	{
		name: 'Gêmeos',
		luckyNumber: 0,
		image: '/cards/s03_gemeos.jpg',
		kassivo1: 'Informação para explicar ',
		kassivo2: 'Comunicados sobre ',
		kassivo3: 'Resultado das variações d',
		kativo4: 'O que você deseja que seja conhecido ',
		kativo5: 'O que se acredita sobre ',
		kativo6: 'Use a maneira mais rápida e ',
	},
	{
		name: 'Câncer',
		luckyNumber: 0,
		image: '/cards/s04_cancer.jpg',
		kassivo1: 'Intuição para proteger ',
		kassivo2: 'Atitudes do passado a respeito d',
		kassivo3: 'Resultado da história d',
		kativo4: 'Suas sensações ',
		kativo5: 'Atitudes do passado a respeito d',
		kativo6: 'Use sua intuição e ',
	},
	{
		name: 'Leão',
		luckyNumber: 0,
		image: '/cards/s05_leao.jpg',
		kassivo1: 'Autoconfiança para criar ',
		kassivo2: 'Tendo oportunidade sobre ',
		kassivo3: 'Resultado da impressão sobre ',
		kativo4: 'Liderança ',
		kativo5: 'A criatividade d',
		kativo6: 'Faça aquilo que deseja e ',
	},
	{
		name: 'Virgem',
		luckyNumber: 0,
		image: '/cards/s06_virgem.jpg',
		kassivo1: 'Métodos para o poder de cura d',
		kassivo2: 'A análise d',
		kassivo3: 'Resultado do perfeccionismo d',
		kativo4: 'O servir aos outros ',
		kativo5: 'Os detalhes d',
		kativo6: 'Faça o que deve fazer e ',
	},
	{
		name: 'Libra',
		luckyNumber: 0,
		image: '/cards/s07_libra.jpg',
		kassivo1: 'Cooperação para aperfeiçoar ',
		kassivo2: 'Decisões sobre ',
		kassivo3: 'Resultado da honestidade mostrado por ',
		kativo4: 'Relacionamentos ',
		kativo5: 'A beleza d',
		kativo6: 'Realize-o com seu(s) companheiro(s) e ',
	},
	{
		name: 'Escorpião',
		luckyNumber: 0,
		image: '/cards/s08_escorpiao.jpg',
		kassivo1: 'Purificação para renascer ',
		kassivo2: 'Os segredos de ',
		kassivo3: 'Resultado do poder d',
		kativo4: 'O extraordinário poder da prece ',
		kativo5: 'Os aspectos mais elevado e mais inferior d',
		kativo6: 'Controle a situação e ',
	},
	{
		name: 'Sagitário',
		luckyNumber: 0,
		image: '/cards/s09_sagitario.jpg',
		kassivo1: 'Esclarecimentos para compreender ',
		kassivo2: 'A sabedoria d',
		kassivo3: 'Resultado da compreensão d',
		kativo4: 'Seus ideais mais elevados ',
		kativo5: 'As regras d',
		kativo6: 'Vá ao mundo e ',
	},
	{
		name: 'Capricórnio',
		luckyNumber: 0,
		image: '/cards/s10_capricornio.jpg',
		kassivo1: 'Dedicação para conseguir ',
		kassivo2: 'Focalizar em ',
		kassivo3: 'Resultado das regras impostas por ',
		kativo4: 'Seus objetivos ',
		kativo5: 'A realidade do dia-a-dia ',
		kativo6: 'Use a maneira mais metódica e ',
	},
	{
		name: 'Aquário',
		luckyNumber: 0,
		image: '/cards/s11_aquario.jpg',
		kassivo1: 'Talento para experimentar com ',
		kassivo2: 'As descobertas d',
		kassivo3: 'Resultado da excentricidade d',
		kativo4: 'Sua visão ',
		kativo5: 'O que há de novo e diferente sobre ',
		kativo6: 'Use a maneira não convencional e ',
	},
	{
		name: 'Peixes',
		luckyNumber: 0,
		image: '/cards/s12_peixes.jpg',
		kassivo1: 'Espiritualidade para finalmente experimentar ',
		kassivo2: 'Confiar no seu conhecimento psíquico d',
		kassivo3: 'Resultado de indecisão de ou a respeito d',
		kativo4: 'Seus sonhos ',
		kativo5: 'Sua idealização d',
		kativo6: 'Seja parte de algo irresistível e ',
	},
]

export const CardsData2: CardsType[] = [
	{
		name: 'Desejos',
		luckyNumber: 0,
		image: '/cards/h01_desejo.jpg',
		kassivo1: 'Quem você é.',
		kassivo2: 'Seus desejos.',
		kassivo3: 'Suas ações.',
		kativo4: 'Imediatamente.',
		kativo5: 'A maneira como você se projeta.',
		kativo6: 'Faça-o por você.',
	},
	{
		name: 'Fortuna',
		luckyNumber: 0,
		image: '/cards/h02_fortuna.jpg',
		kassivo1: 'Suas crenças.',
		kassivo2: 'O que você vale.',
		kassivo3: 'Suas fontes.',
		kativo4: 'Pacientemente.',
		kativo5: 'O que você deseja.',
		kativo6: 'Obtenha o que você precisa.',
	},
	{
		name: 'Planos',
		luckyNumber: 0,
		image: '/cards/h03_planos.jpg',
		kassivo1: 'Suas ideias.',
		kassivo2: 'Ideias e viagens a curto prazo.',
		kassivo3: 'Quem e o que está à sua volta.',
		kativo4: 'Por meio das ligações certas.',
		kativo5: 'A idéias em questão.',
		kativo6: 'Prossiga com suas ideias.',
	},
	{
		name: 'Suporte',
		luckyNumber: 0,
		image: '/cards/h04_suporte.jpg',
		kassivo1: 'O que o faz seintir-se seguro.',
		kassivo2: 'Suportar.',
		kassivo3: 'Seu lar ou sua base familiar.',
		kativo4: 'Como você fez no passado.',
		kativo5: 'Seu lar ou sua base familiar.',
		kativo6: 'Faça o que o faz sentir-se seguro.',
	},
	{
		name: 'Amor e Jogos',
		luckyNumber: 0,
		image: '/cards/h05_amor_jogos.jpg',
		kassivo1: 'O poder do amor.',
		kassivo2: 'Jogos de investimentos e outros jogos.',
		kassivo3: 'Suas criações.',
		kativo4: 'Com a confiança de uma criança.',
		kativo5: 'Divertimento, Romance e Criações artísticas.',
		kativo6: 'Faça-o dramaticamente.',
	},
	{
		name: 'Fartura',
		luckyNumber: 0,
		image: '/cards/h06_fartura.jpg',
		kassivo1: 'Servir aos outros',
		kassivo2: 'O que é bom para nós',
		kassivo3: 'Seu trabalho',
		kativo4: 'Como um trabalho de tempo integral',
		kativo5: 'Servir',
		kativo6: 'Faça um pouco de cada vez',
	},
	{
		name: 'Cooperação',
		luckyNumber: 0,
		image: '/cards/h07_cooperacao.jpg',
		kassivo1: 'Equilíbrio.',
		kassivo2: 'Cooperação.',
		kassivo3: 'Seu(s) sócio(s).',
		kativo4: 'Diplomaticamente.',
		kativo5: 'Relacionamentos.',
		kativo6: 'Faça um bom negócio.',
	},
	{
		name: 'Poder',
		luckyNumber: 0,
		image: '/cards/h08_poder.jpg',
		kassivo1: 'Poder pessoal.',
		kassivo2: 'Os mistérios da vida.',
		kassivo3: 'Uma oportunidade maior.',
		kativo4: 'Como ordem de vida ou morte.',
		kativo5: 'Obtendo e usando poder.',
		kativo6: 'Use os recursos das outras pessoas.',
	},
	{
		name: 'Viagens',
		luckyNumber: 0,
		image: '/cards/h09_viagens.jpg',
		kassivo1: 'Valores espirituais.',
		kassivo2: 'Ideias e viagens a longo prazo.',
		kassivo3: 'O que deve ser partilhado.',
		kativo4: 'Como se tivesse vindo de Deus.',
		kativo5: 'As filosofias e as leis envolvidas.',
		kativo6: 'Faça-o de maneira formidável.',
	},
	{
		name: 'Sucesso',
		luckyNumber: 0,
		image: '/cards/h10_sucesso.jpg',
		kassivo1: 'Seu destino.',
		kassivo2: 'Para onde você está indo.',
		kassivo3: 'Sua carreira.',
		kativo4: 'Responsabilidade.',
		kativo5: 'Sucesso.',
		kativo6: 'Torne-se alguém com poder e posição social.',
	},
	{
		name: 'Liberdade',
		luckyNumber: 0,
		image: '/cards/h11_liberdade.jpg',
		kassivo1: 'Liberdade.',
		kassivo2: 'Associações.',
		kassivo3: 'Seu círculo de amizade.',
		kativo4: 'Pelo bem da humanidade.',
		kativo5: 'Suas esperanças e aspirações.',
		kativo6: 'Faça-o para o futuro.',
	},
	{
		name: 'Fé',
		luckyNumber: 0,
		image: '/cards/h12_fe.jpg',
		kassivo1: 'Sua fé.',
		kassivo2: 'Tendências ocultas.',
		kassivo3: 'Grande intuição ou acontecimentos opressivos.',
		kativo4: 'Buscando a unidade com o tudo que existe.',
		kativo5: 'Filantropia.',
		kativo6: 'Mantenha seu envolvimento em segredo.',
	},
]
