import UserEntity from '@/classes/user/UserClass'
import { verifyFirstDay } from '../verify/verifyFirstDay'

export async function firstDayPromotion(entity: UserEntity, user?: any) {
	const { hasPromotion, isWithinTime, promotion } = verifyFirstDay(entity, user)
	if (!hasPromotion) {
		if (isWithinTime) entity.hook.setShowPromotion(promotion!)
		else {
			const promotions = user
				? user.promotions || []
				: entity.hook.user.promotions || []
			await entity.update({
				id: entity.hook.firebaseUser?.uid,
				promotions: [...promotions, promotion?.promotionId],
			})
		}
	} else {
		entity.hook.setShowPromotion(null)
	}
	return { hasPromotion, isWithinTime, promotion }
}
