import UserEntity from '../../UserClass'
import { setUser } from '../userData/setUser'

export const useTouch = async (entity: UserEntity, touchUsed: number) => {
	await entity.update({
		id: entity.hook.firebaseUser?.uid,
		touch: entity.hook.user.touch - touchUsed,
	})
	await setUser(entity)
}
