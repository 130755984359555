import { useState } from 'react'
import { IPayments } from './interface/payments'

export default function usePaymentsHook() {
	//States
	const [payments, setPayments] = useState<IPayments[]>([])

	return {
		payments,
		setPayments,
	}
}
