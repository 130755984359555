import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useUserContext } from '@/contexts/UserContext'
import { signUp } from '@/firebase/auth'
import SignUpForm from './form/form'
import { SignUpFormSchema } from './form/schema'

export default function SignUp({
	setPage,
}: {
	setPage: (page: 'signIn' | 'signUp' | 'forgot') => void
}) {
	const { formMethods } = SignUpForm()

	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods

	const { insert } = useUserContext()

	const submit = async (form: SignUpFormSchema) => {
		const res = await signUp(form.email, form.password, form.confirmPassword)
		if (res?.result?.user.uid)
			insert({
				data: {
					id: res?.result?.user.uid,
					email: form.email,
					coins: 0,
					createdAt: new Date().toISOString(),
				},
				customId: res?.result?.user.uid,
			})
	}

	return (
		<div className="flex flex-col items-center gap-4">
			<p className="text-white font-bold">Criar nova conta:</p>
			<form
				className="w-full xl:w-1/3 flex flex-col gap-6"
				onSubmit={handleSubmit(submit)}
			>
				<Input
					label="Email"
					{...register('email')}
					placeholder="johndoe@gmail.com"
				/>
				<Input
					label="Senha"
					{...register('password')}
					type="password"
					placeholder="********"
				/>
				<Input
					label="Repetir Senha"
					{...register('confirmPassword')}
					type="password"
					placeholder="********"
				/>
				<div>
					<p className="text-white text-small text-center mb-1">
						Ao criar conta, você aceita nossos termos de uso e confirma ter mais
						de 16 anos:
					</p>
					<Button type="submit" loading={isSubmitting} className="w-full">
						Criar conta
					</Button>
				</div>
			</form>
			<div className="flex items-end h-1/3 justify-center w-full">
				<Button
					className="font-bold"
					disabled={isSubmitting}
					onClick={() => {
						setPage('signIn')
					}}
					variant="ghostLight"
				>
					Voltar
				</Button>
			</div>
		</div>
	)
}
