import Coins from '@/assets/icons/coins.svg'
import Stars from '@/assets/icons/stars.svg'
import { isFirstBuy } from '@/classes/payments/actions/howManyPayments'
import { handleBuyCoins } from '@/classes/user/actions/buy/buyCoins'
import { removeWeekly } from '@/classes/user/actions/promotions/remove/removeWeekly'
import { Button } from '@/components/ui/button'
import { usePaymentsContext } from '@/contexts/PaymentsContext'
import { useUserContext } from '@/contexts/UserContext'
import { getTimeLeft } from '@/utils/date'
import { useEffect, useState } from 'react'
import { useCountdown } from 'usehooks-ts'
import { StoreType } from './StoreData'

export default function CoinCard({
	data,
}: {
	data: StoreType
}) {
	const paymentsEntity = usePaymentsContext()
	const [loading, setLoading] = useState(false)
	const userEntity = useUserContext()
	const hasUsedPromotion =
		data.promotion &&
		userEntity.hook.user.promotions?.includes(data.promotionId)

	const [count, { startCountdown, stopCountdown }] = useCountdown({
		countStart: getTimeLeft(hasUsedPromotion, data, userEntity).expiresIn,
		intervalMs: 1000,
		countStop: 0,
	})

	const [timeLeft, setTimeLeft] = useState(
		getTimeLeft(hasUsedPromotion, data, userEntity).text,
	)

	useEffect(() => {
		stopCountdown()
		startCountdown()
	}, [userEntity.hook.user])

	useEffect(() => {
		if (data.once || !data.promotion) return
		if (count === 0) {
			if (data.promotionId === 'touchWeekly') {
				removeWeekly(userEntity)
			}
		} else {
			setTimeLeft(getTimeLeft(hasUsedPromotion, data, userEntity).text)
		}
	}, [count])

	const firstBuy = (!hasUsedPromotion &&
		!data.isFree &&
		!data.promotion &&
		!data.once &&
		data.price &&
		isFirstBuy(paymentsEntity)) as boolean

	return (
		<div className="rounded-[10px] bg-primary-300 flex flex-col">
			<div className="p-4">
				<div className="flex items-center justify-center gap-1">
					<img src={data.name === 'Toque do Destino' ? Stars : Coins} alt="" />
					<p className="text-white text-[2rem] lg:text-[2.5rem] font-bold">
						{data.amount}
					</p>
				</div>
				<p className="text-white text-extraSmall lg:text-small font-bold text-center">
					{data.name}
				</p>
			</div>
			<div className="w-full relative items-center flex justify-center">
				<Button
					loading={loading}
					disabled={hasUsedPromotion}
					onClick={() => {
						if (data.onclick) data.onclick(userEntity)
						else
							handleBuyCoins({
								setLoading,
								data: {
									coins: data.amount,
									priceId: data.priceId!,
								},
								userId: userEntity.hook.firebaseUser?.uid || '',
								firstBuy,
								userEntity,
							})
					}}
					className="rounded-none w-full space-x-2 rounded-b-[10px] data-[firstBuy=true]:line-through"
				>
					<>
						{firstBuy && (
							<p className="text-red-500 text-small font-bold ml-1 line-through">
								R${data.price}
							</p>
						)}
						<p className="text-small lg:text-[1.25rem] font-bold">
							{hasUsedPromotion
								? data.once
									? 'Adquirido'
									: timeLeft
								: data.isFree
									? data.price
									: 'R$' +
										(firstBuy ? (+data.price / 2).toFixed(2) : data.price)}
						</p>
					</>
				</Button>
			</div>
		</div>
	)
}
