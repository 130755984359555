import { IPayments } from '@/classes/payments/interface/payments'
import { Button } from '@/components/ui/button'
import { usePaymentsContext } from '@/contexts/PaymentsContext'
import { format } from 'date-fns'
import { ArrowLeft } from 'lucide-react'
import { useHistory } from 'react-router'

export default function Purchases() {
	const {
		hook: { payments },
	} = usePaymentsContext()
	const navigate = useHistory()
	return (
		<div className="p-8 space-y-4">
			<div className="flex items-center gap-2">
				<Button
					onClick={() => navigate.replace('/')}
					className="text-white w-auto"
					variant="ghostLight"
					size="icon"
				>
					<ArrowLeft />
				</Button>
				<p className="text-white font-bold">Histórico de Compras</p>
			</div>
			<div className="space-y-8">
				{payments?.map((payment: IPayments) => {
					return (
						<div>
							<div className="flex items-center justify-between">
								<p className="text-small text-white font-bold">
									Compra - #{payment.id}
								</p>
								{/* <button type="button">
									<p className="text-extraSmall text-white">
										Preciso de ajuda com esse pedido
									</p>
								</button> */}
							</div>
							<p className="text-small text-primary-100">
								Data da Compra:{' '}
								{format(new Date(payment.createdAt), 'dd/MM/yyyy HH:mm')}
							</p>
						</div>
					)
				})}
			</div>
		</div>
	)
}
