import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	where,
} from 'firebase/firestore'
import { db } from './firebase'

async function getByField(
	collectionName: string,
	field: string,
	value: string,
) {
	const colRef = collection(db, collectionName)
	const q = query(colRef, where(field, '==', value)) // Create the query
	let result: any = []
	let error = null

	try {
		const querySnapshot = await getDocs(q)
		querySnapshot.forEach((doc) => {
			result.push({ id: doc.id, ...doc.data() })
		})

		if (result.length === 0) {
			error = []
		}
	} catch (e) {
		error = e
	}

	return { result, error }
}

async function get(collection: string, id: string) {
	const docRef = doc(db, collection, id)
	let result = null
	let error = null

	try {
		result = (await getDoc(docRef)).data()
	} catch (e) {
		error = e
	}

	return { result, error }
}

async function getAll(colllection: string) {
	let result = null
	let error = null
	try {
		result = (await getDocs(collection(db, colllection))).docs.map((doc) =>
			doc.data(),
		)
	} catch (e) {
		error = e
	}

	return { result, error }
}

async function getRealTime(colllection: string) {
	let result = null
	let error = null
	try {
		result = onSnapshot(doc(db, colllection), (doc) => {
			doc.data()
		})
	} catch (e) {
		error = e
	}

	return { result, error }
}

async function insert(colllection: string, data: any, customId?: string) {
	let result = null
	let error = null

	try {
		if (customId)
			result = await await setDoc(doc(db, colllection, customId), data)
		else result = await await addDoc(collection(db, colllection), data)
	} catch (e) {
		error = e
	}

	if (result) {
		const id = result.id
		result = {
			...result,
			id,
		}
		update(colllection, id, { id })
	}

	return { result, error }
}

async function update(collection: string, id: string, data: any) {
	let result = null
	let error = null

	try {
		result = await setDoc(doc(db, collection, id), data, {
			merge: true,
		})
	} catch (e) {
		error = e
	}

	return { result, error }
}

async function remove(collection: string, id: string) {
	let result = null
	let error = null

	try {
		result = await deleteDoc(doc(db, collection, id))
	} catch (e) {
		error = e
	}

	return { result, error }
}

export { get, getAll, getByField, getRealTime, insert, remove, update }
