import offFirstBuy from '@/assets/app/evento.png'
import { isFirstBuy } from '@/classes/payments/actions/howManyPayments'
import LowerThird from '@/components/LowerThird'
import PromotionModal from '@/components/PromotionModal'
import PromotionPopup from '@/components/PromotionPopup'
import { Button } from '@/components/ui/button'
import { usePaymentsContext } from '@/contexts/PaymentsContext'
import { useQuestionContext } from '@/contexts/QuestionContext'
import { useUserContext } from '@/contexts/UserContext'
import { useEffect, useState } from 'react'
import Consultation from '../sections/consultation/Consultation'
import useConsultationHook from '../sections/consultation/useConsultationHook'
import DestinyTouch from '../sections/destinyTouch/DestinyTouch'
import Historic from '../sections/historic/Historic'
import LastConsultation from '../sections/lastConsult/LastConsultation'

export default function MobileHome({}) {
	const userEntity = useUserContext()
	const {
		hook: { showPromotion },
	} = userEntity
	const paymentsEntity = usePaymentsContext()
	const {
		hook: { questions },
	} = useQuestionContext()

	const [open, setOpen] = useState(false)

	const [top, setTop] = useState(window.innerHeight / 2.5)

	useEffect(() => {
		if (showPromotion) {
			const promotionElement = document.getElementById('pin')
			if (promotionElement) {
				setTop(window.innerHeight / 2.5)
			}
		}
	}, [showPromotion, document.getElementById('pin')])

	const { handleDestinyTouch } = useConsultationHook()
	const [loading, setLoading] = useState(false)

	return (
		<div>
			{isFirstBuy(paymentsEntity) && <LowerThird image={offFirstBuy} />}

			{showPromotion && <PromotionPopup top={top} setOpen={setOpen} />}

			{questions?.length === 0 && (
				<div className="bg-primary-900 mt-4 flex flex-col items-center justify-center px-8 py-4">
					<p className="text-white font-bold">Ainda sem consultas?</p>
					<p className="text-white text-center text-small mt-2 mb-4">
						Realize seu primeiro{' '}
						<span className="text-secondary-500">Toque do Destino</span>{' '}
						inteiramente grátis!
					</p>
					<Button
						loading={loading}
						onClick={async () => {
							setLoading(true)
							await handleDestinyTouch(true)
							setLoading(false)
						}}
						className="w-full text-primary font-bold"
					>
						1x Toque do destino
					</Button>
				</div>
			)}

			{questions && questions?.length > 0 && (
				<>
					<div className="space-y-8 p-8 pb-0">
						<Consultation />
						<div id="pin" />
						<LastConsultation />
						{questions && questions.length > 0 && (
							<DestinyTouch question={questions[0]} />
						)}
					</div>
					<div className="mt-8">
						<Historic />
					</div>
				</>
			)}

			{open && <PromotionModal open={open} setOpen={setOpen} />}
		</div>
	)
}
