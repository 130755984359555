import { Button } from '@/components/ui/button'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { useHistory } from 'react-router'
import CoinCard from './CoinCard'
import { StoreData } from './StoreData'

export default function Shop() {
	const navigate = useHistory()

	return (
		<div className="p-8 space-y-4">
			<div className="flex items-center gap-2">
				<Button
					onClick={() => navigate.replace('/')}
					className="text-white w-auto"
					variant="ghostLight"
					size="icon"
				>
					<ArrowLeft />
				</Button>
				<p className="text-white font-bold">Loja</p>
			</div>

			<div className="flex items-center justify-between border-b border-white pb-4">
				<div className="space-y-2">
					<p className="text-large font-bold text-white">
						Histórico de Compras
					</p>
					<p className="text-extraSmall text-white">
						Nossas diretrizes respondem todas as dúvidas, antes de fazer
						pagamentos pelo aplicativo por favor, leia elas aqui.
					</p>
				</div>
				<Button
					onClick={() => {
						navigate.push('/purchases')
					}}
					className="text-white"
					variant="ghostLight"
					size="icon"
				>
					<ArrowRight />
				</Button>
			</div>

			<p className="text-white font-bold pt-4">Loja de Moedas das Sorte</p>
			<div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
				{StoreData.filter((e) => !e.promotion).map((e, i) => {
					return <CoinCard data={e} key={`each_non_promotion_${i}`} />
				})}
			</div>

			<p className="text-white font-bold pt-4">Promoção</p>
			<div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
				{StoreData.filter((e) => e.promotion).map((e, i) => {
					return <CoinCard data={e} key={`each_promotion_${i}`} />
				})}
			</div>
		</div>
	)
}
