import { IQuestion } from '@/classes/questions/interface/question'
import { Button } from '@/components/ui/button'
import { useQuestionContext } from '@/contexts/QuestionContext'
import { StringCutter } from '@/utils/string'
import { useHistory } from 'react-router'

export default function HistoricBox({
	question,
}: {
	question: IQuestion
}) {
	const {
		hook: { setCurrentQuestion },
	} = useQuestionContext()
	const navigate = useHistory()
	return (
		<div className="bg-primary-300 min-w-[12rem] w-[12rem] max-w-[12rem] lg:w-full lg:max-w-full p-4 items-center justify-center divide-y-2 divide-secondary rounded-[10px] flex flex-col">
			<div className="flex flex-col lg:flex-row-reverse lg:w-full lg:justify-between items-center justify-center gap-2 pb-4">
				<p className="text-primary-100 text-extraSmall">{question.createdAt}</p>
				<p className="font-bold text-secondary text-small lg:text-[1rem] h-8 lg:h-auto">
					{question.destinyTouch
						? 'Toque do destino'
						: StringCutter(question.question, 32)}
				</p>
			</div>
			<div className="flex flex-col items-center justify-center lg:items-start gap-2 pt-4 lg:w-full">
				<p className="text-white text-extraSmall lg:text-small h-12 lg:h-auto">
					{question.kativo4}
				</p>
				<Button
					onClick={() => {
						setCurrentQuestion(question)
						navigate.push('/details')
					}}
					className="rounded-[10px] w-full"
					variant="default"
				>
					Ler completo.
				</Button>
			</div>
		</div>
	)
}
