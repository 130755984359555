import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { SignInFormSchema, SignInSchema } from './schema'

export default function SignInForm() {
	const formMethods = useForm<SignInFormSchema>({
		resolver: zodResolver(SignInSchema),
	})

	return {
		formMethods,
	}
}
