import UserEntity from '@/classes/user/UserClass'
import { setUser } from '../../userData/setUser'

export const removeWeekly = async (entity: UserEntity) => {
	const userPromotions = entity.hook.user.promotions || []
	if (!userPromotions.includes('touchWeekly')) return
	await entity.update({
		id: entity.hook.firebaseUser?.uid,
		promotions: userPromotions.filter(
			(promotion: any) => promotion !== 'touchWeekly',
		),
		touchWeekly: null,
	})
	await setUser(entity)
}
