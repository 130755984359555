import { useUserContext } from '@/contexts/UserContext'
import { signOut } from '@/firebase/auth'
import { toogleMenu } from '@/utils/menu'
import { IonContent, IonFooter, IonMenu } from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import DeleteAccount from './DeleteAccount/DeleteAccount'
import { Button } from './ui/button'

interface AppPage {
	url?: string
	title: string
	showAnon: boolean
	showCondition?: string
	childs?: AppPage[]
}

const appPages: AppPage[] = [
	{
		title: 'Home',
		url: '/',
		showAnon: true,
	},
	{
		title: 'Loja',
		url: '/shop',
		showAnon: false,
	},
	// {
	// 	title: 'Configurações',
	// 	showAnon: false,
	// 	childs: [
	// 		{
	// 			title: 'Informações Pessoais',
	// 			url: 'profile',
	// 			showAnon: false,
	// 		},
	// 		{
	// 			title: 'Carteira e Pagamentos',
	// 			url: 'wallet',
	// 			showAnon: false,
	// 		},
	// 	],
	// },
	{
		title: 'Históricos',
		showAnon: false,
		childs: [
			// {
			// 	title: 'Histórico de Depósito',
			// 	url: 'deposits',
			// 	showAnon: false,
			// },
			{
				title: 'Histórico de Compras',
				url: 'purchases',
				showAnon: false,
			},
			{
				title: 'Histórico de Consultas',
				url: 'consultationHistory',
				showAnon: false,
			},
		],
	},
]

export default function Menu() {
	const navigate = useHistory()

	const {
		hook: { user, firebaseUser },
	} = useUserContext()

	const [confirmation, setConfirmation] = useState(false)

	return (
		<IonMenu disabled={!firebaseUser} contentId="main" type="overlay">
			<IonContent>
				<div className="space-y-4">
					<p className="font-bold text-white px-8 pt-8">
						Olá, {user?.email?.split('@')[0]}
					</p>

					<div className="flex flex-col gap-8 px-8 py-4">
						{appPages.map((page) => {
							return (
								<div key={`each_app_page_${page.title}`} className="space-y-4">
									<Button
										data-navigable={!page.childs && page.url}
										className="data-[navigable=false]:cursor-default font-bold p-0 h-auto"
										onClick={() => {
											if (page.childs || !page.url) return
											navigate.push(page.url)
											toogleMenu()
										}}
										variant="ghostLight"
									>
										{page.title}
									</Button>

									{page.childs && (
										<div className="flex flex-col justify-start items-start gap-4 px-4">
											{page.childs.map((child) => {
												return (
													<Button
														key={`each_app_page_child_${child.title}`}
														data-navigable={!child.childs && child.url}
														className="data-[navigable=false]:cursor-default p-0 h-auto"
														onClick={() => {
															if (child.childs || !child.url) return
															navigate.push(child.url)
															toogleMenu()
														}}
														variant="ghostLight"
													>
														{child.title}
													</Button>
												)
											})}
										</div>
									)}
								</div>
							)
						})}
					</div>
				</div>
			</IonContent>
			<IonFooter>
				<div className="flex flex-col gap-4 items-center justify-center">
					<Button className="bg-red-300 w-2/3 text-white" onClick={signOut}>
						Sair da conta
					</Button>
					<Button
						onClick={() => {
							setConfirmation(true)
							toogleMenu()
						}}
						variant="ghost"
						className="text-red-300 mb-8 text-[0.65rem]"
					>
						Excluir conta
					</Button>
				</div>
			</IonFooter>

			{confirmation && (
				<DeleteAccount
					confirmation={confirmation}
					setConfirmation={setConfirmation}
				/>
			)}
		</IonMenu>
	)
}
