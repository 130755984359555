import { useUserContext } from '@/contexts/UserContext'
import { toogleMenu } from '@/utils/menu'
import { Menu } from 'lucide-react'
import { useHistory } from 'react-router'
import { useMediaQuery } from 'usehooks-ts'
import { Button } from './ui/button'

export default function Header() {
	const {
		hook: { user },
	} = useUserContext()

	const isLg = useMediaQuery('(min-width: 1024px)')

	const navigate = useHistory()

	const isHome = navigate.location.pathname === '/'

	return (
		<div
			data-home={isHome}
			style={{
				borderRadius: isLg && isHome ? '0 0 1.5rem 0' : '',
			}}
			className="bg-primary-900 h-24 flex items-center justify-between px-4 data-[home=true]:lg:w-1/3 "
		>
			<div className="flex text-white font-bold items-center gap-4">
				<Button
					onClick={() => {
						toogleMenu()
					}}
					variant="ghostLight"
					size="icon"
				>
					<Menu />
				</Button>
				<p className="capitalize">{user?.email?.split('@')[0]}</p>
			</div>
			<div className="flex items-center gap-2">
				{/* <Button variant="ghostLight" size="icon">
					<Bell />
				</Button> */}
				{/* <Button variant="ghostLight" size="icon">
					<User />
				</Button> */}
			</div>
		</div>
	)
}
