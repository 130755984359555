import UserEntity from '../../../UserClass'
import { setUser } from '../../userData/setUser'

export const addMonthly = async (entity: UserEntity) => {
	const userPromotions = entity.hook.user.promotions || []
	if (userPromotions.includes('coinMonthly')) return
	await entity.update({
		id: entity.hook.firebaseUser?.uid,
		coins: entity.hook.user.coins + 1,
		promotions: [...userPromotions, 'coinMonthly'],
		coinMonthly: new Date().toLocaleString(),
	})
	await setUser(entity)
}
