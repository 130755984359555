import dayModal from "@/assets/app/promoDayIn.png";
import dayPopup from "@/assets/app/promoDayOut.png";

import weekModal from "@/assets/app/promoWeekIn.png";
import weekPopup from "@/assets/app/promoWeekOut.png";

import monthModal from "@/assets/app/promoMonthIn.png";
import monthPopup from "@/assets/app/promoMonthOut.png";

export interface PromotionsType {
  name: string;
  priceId: string;
  type: "coin" | "touch" | "mix";
  time: number;
  promotionId: string;
  coin: number;
  touch: number;
  popupImage: string;
  modalImage: string;
  isFree: boolean;
}

export const PromotionsData: PromotionsType[] = [
  {
    name: "Promoção de Boas Vindas!",
    priceId: "price_1QPDgLAgRNYgDKJ1NOY3blX5",
    type: "mix",
    promotionId: "firstDay",
    time: 24,
    coin: 7,
    touch: 1,
    isFree: false,
    popupImage: dayPopup,
    modalImage: dayModal,
  },
  {
    name: "Minha primeira semana!",
    priceId: "price_1QPDhmAgRNYgDKJ13hxrAN0G",
    type: "mix",
    promotionId: "firstWeek",
    time: 24 * 7,
    coin: 13,
    touch: 13,
    isFree: false,
    popupImage: weekPopup,
    modalImage: weekModal,
  },
  {
    name: "Meu primeiro mês!",
    priceId: "price_1QPDiIAgRNYgDKJ12YeTc9lJ",
    type: "mix",
    promotionId: "firstMonth",
    time: 24 * 30,
    coin: 100,
    touch: 200,
    isFree: false,
    popupImage: monthPopup,
    modalImage: monthModal,
  },
];
