import FortuneCard from '@/components/FortuneCard'
import { Button } from '@/components/ui/button'
import { useQuestionContext } from '@/contexts/QuestionContext'
import { CardsData0, CardsData1, CardsData2 } from '@/data/cards/CardsData'
import { ArrowLeft } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

export default function DetailsDesktop() {
	const {
		hook: { currentQuestion },
	} = useQuestionContext()

	const navigate = useHistory()
	const [currentCard, setCurrentCard] = useState<number>(0)

	useEffect(() => {
		if (!currentQuestion) {
			navigate.replace('/')
		}

		const containerH =
			document.getElementById('container')?.getBoundingClientRect().height || 0

		const cards = document.getElementsByClassName('fortune-card')

		for (let i = 0; i < cards.length; i++) {
			if (containerH <= 0) return
			const card = cards[i] as HTMLElement
			card.style.height = `${containerH}px`
		}
	}, [currentQuestion])

	return (
		<div className="flex flex-col gap-8 mx-8">
			<div className="flex items-center gap-4 mt-8">
				<Button
					onClick={() => navigate.replace('/')}
					className="text-white w-auto"
					variant="ghostLight"
					size="icon"
				>
					<ArrowLeft />
				</Button>
				<p className="text-secondary font-bold text-large w-full">
					{currentQuestion?.destinyTouch
						? 'Toque do destino'
						: currentQuestion?.question}
				</p>
			</div>
			<div className="flex items-start bg-primary-900 p-8 rounded-[10px]">
				<div className="w-1/2 flex flex-col gap-4">
					<p className="mx-auto text-center text-secondary font-bold text-[1.25rem] h-[3rem]">
						Os Regentes
					</p>
					<div className="flex items-center gap-8">
						<FortuneCard
							cardNumber={currentQuestion?.numbers[0] || 0}
							cardSet={CardsData0}
							showingCard={currentCard > 0}
							currentCard={currentCard === 1}
							onClick={() => {
								if (currentCard === 1) setCurrentCard(0)
								else setCurrentCard(1)
							}}
							label
						/>
						<FortuneCard
							cardNumber={currentQuestion?.numbers[1] || 0}
							cardSet={CardsData1}
							showingCard={currentCard > 0}
							currentCard={currentCard === 2}
							onClick={() => {
								if (currentCard === 2) setCurrentCard(0)
								else setCurrentCard(2)
							}}
							label
						/>
						<FortuneCard
							cardNumber={currentQuestion?.numbers[2] || 0}
							cardSet={CardsData2}
							showingCard={currentCard > 0}
							currentCard={currentCard === 3}
							onClick={() => {
								if (currentCard === 3) setCurrentCard(0)
								else setCurrentCard(3)
							}}
							label
						/>
					</div>
				</div>
				<div className="w-1/2 flex flex-col items-center">
					<p className="text-primary-100 text-extraSmall w-full text-end h-[3rem]">
						{currentQuestion?.createdAt}
					</p>
					<div id="container">
						<div className="flex items-center divide-x divide-primary-100 my-8">
							<div className="flex flex-col px-8 gap-2">
								<p className="text-large text-secondary font-bold">
									Karma Passivo
								</p>
								<p className="text-white">
									{currentQuestion?.kassivo1} {currentQuestion?.kassivo2}{' '}
									{currentQuestion?.kassivo3}
								</p>
							</div>
							<div className="flex flex-col px-8 gap-2">
								<p className="text-large text-secondary font-bold">
									Karma Ativo
								</p>
								<p className="text-white">
									{currentQuestion?.kativo4}
									{currentQuestion?.kativo5}
									{currentQuestion?.kativo6}
								</p>
							</div>
						</div>
						<div className="relative">
							<div className="flex flex-col overflow-y-auto max-h-[16rem] px-8 pb-8">
								<div className="flex flex-col gap-2">
									<p className="text-large text-secondary font-bold text-center">
										Interpretação Karma Passivo
									</p>
									<div className="flex flex-col gap-2 text-white">
										<p className="text-small font-bold text-secondary">
											{currentQuestion?.kassivo1}
										</p>
										<p>{currentQuestion?.kassivo1Intepretation}</p>
									</div>
									<div className="flex flex-col gap-2 text-white">
										<p className="text-small font-bold text-secondary">
											{currentQuestion?.kassivo2}:
										</p>
										<p>{currentQuestion?.kassivo2Intepretation}</p>
									</div>
									<div className="flex flex-col gap-2 text-white">
										<p className="text-small font-bold text-secondary">
											{currentQuestion?.kassivo3}:
										</p>
										<p>{currentQuestion?.kassivo3Intepretation}</p>
									</div>
								</div>
								<div className="flex flex-col gap-2">
									<p className="text-large text-secondary font-bold text-center">
										Interpretação Karma Ativo
									</p>
									<div className="flex flex-col gap-2 text-white">
										<p className="text-small font-bold text-secondary">
											{currentQuestion?.kativo4}
										</p>
										<p>{currentQuestion?.kativo4Intepretation}</p>
									</div>
									<div className="flex flex-col gap-2 text-white">
										<p className="text-small font-bold text-secondary">
											{currentQuestion?.kativo5}:
										</p>
										<p>{currentQuestion?.kativo5Intepretation}</p>
									</div>
									<div className="flex flex-col gap-2 text-white">
										<p className="text-small font-bold text-secondary">
											{currentQuestion?.kativo6}:
										</p>
										<p>{currentQuestion?.kativo6Intepretation}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
