import Messaging from '@/firebase/messaging'
import { MESSAGING_TOPICS } from '@/utils/messaging_topics'
import UserEntity from '../../UserClass'

export const initMessaging = async (entity: UserEntity) => {
	const { init } = Messaging()
	init(
		MESSAGING_TOPICS.map((topic) => {
			return {
				...topic,
				subscribed: true,
			}
		}),
	).then((token) => {
		if (!token || entity.hook.user.token === token) return
		entity.update({
			id: entity.hook.firebaseUser?.uid,
			token,
		})
	})
}
