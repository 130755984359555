import UserEntity from '@/classes/user/UserClass'
import { firstDayPromotion } from './firstDay'
import { firstMonthPromotion } from './firstMonth'
import { firstWeekPromotion } from './firstWeek'

export async function PromotionsManager(entity: UserEntity, user: any) {
	const firstDay = await firstDayPromotion(entity, user)
	if (!firstDay.isWithinTime || firstDay.hasPromotion) {
		const firstWeek = await firstWeekPromotion(entity, user)
		if (!firstWeek.isWithinTime || firstWeek.hasPromotion) {
			const firstMonth = await firstMonthPromotion(entity, user)
		}
	}
}
