import { menuController } from '@ionic/core/components'

export const menuOnOff = (bool: boolean) => {
	bool ? menuController.open() : menuController.close()
}

export const toogleMenu = async () => {
	const isOpen = await menuController.isOpen()
	isOpen ? menuController.close() : menuController.open()
}
