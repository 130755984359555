import Header from '@/components/Header'
import { useUserContext } from '@/contexts/UserContext'
import { auth } from '@/firebase/firebase'
import Auth from '@/pages/auth/Auth'
import ConsultationHistory from '@/pages/logged/consultationHistory/ConsultationHistory'
import Deposits from '@/pages/logged/deposits/Deposits'
import Details from '@/pages/logged/details/Details'
import Home from '@/pages/logged/home/Home'
import Purchases from '@/pages/logged/purchases/Purchases'
import Shop from '@/pages/logged/shop/Shop'
import Success from '@/pages/logged/success/Success'
import { IonContent, IonPage } from '@ionic/react'
import { useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router'

export default function Routing() {
	const {
		hook: { firebaseUser },
	} = useUserContext()

	const navigate = useHistory()

	return (
		<>
			<IonPage>
				<IonContent>
					{firebaseUser && <Header />}
					{/* <PageContainer> */}
					<Switch>
						<Route path="/" exact={true}>
							<RenderLoggedPage>
								<Home />
							</RenderLoggedPage>
						</Route>

						<Route path="/details" exact={true}>
							<RenderLoggedPage>
								<Details />
							</RenderLoggedPage>
						</Route>
						<Route path="/shop" exact={true}>
							<RenderLoggedPage>
								<Shop />
							</RenderLoggedPage>
						</Route>
						<Route path="/success" exact={true}>
							<RenderLoggedPage>
								<Success />
							</RenderLoggedPage>
						</Route>
						<Route path="/consultationHistory" exact={true}>
							<RenderLoggedPage>
								<ConsultationHistory />
							</RenderLoggedPage>
						</Route>
						<Route path="/purchases" exact={true}>
							<RenderLoggedPage>
								<Purchases />
							</RenderLoggedPage>
						</Route>
						<Route path="/deposits" exact={true}>
							<RenderLoggedPage>
								<Deposits />
							</RenderLoggedPage>
						</Route>
						<Route path="/auth" exact={true}>
							<RenderPublicPage>
								<Auth />
							</RenderPublicPage>
						</Route>
					</Switch>
				</IonContent>
				{/* </PageContainer> */}
			</IonPage>
		</>
	)
}
const RenderPublicPage = ({ children }: { children: React.ReactNode }) => {
	const navigate = useHistory()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				localStorage.clear()
				navigate.replace('/')
			}
			setLoading(false)
		})

		return () => unsubscribe() // Clean up the subscription
	}, [navigate])

	if (loading) return null // Optionally, you can show a loading spinner here

	return <>{children}</>
}

const RenderLoggedPage = ({ children }: { children: React.ReactNode }) => {
	const navigate = useHistory()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user === null) {
				localStorage.clear()
				navigate.replace('/auth')
			}
			setLoading(false)
		})

		return () => unsubscribe() // Clean up the subscription
	}, [navigate])

	if (loading) return null // Optionally, you can show a loading spinner here

	return <>{children}</>
}
