import { X } from 'lucide-react'
import { Button } from './ui/button'

import PromotionBackground from '@/assets/app/backgroundPromo.png'
import { useUserContext } from '@/contexts/UserContext'
import { useState } from 'react'

export default function PromotionPopup({
	top,
	setOpen,
}: {
	top: number
	setOpen: (bool: boolean) => void
}) {
	const {
		hook: { showPromotion },
	} = useUserContext()
	const [show, setShow] = useState(true)

	return show ? (
		<div
			id="promotion"
			style={{
				top,
				backgroundImage: `url(${PromotionBackground})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
			className="absolute flex items-center justify-center z-20 right-0 w-[6rem] h-[6rem]"
		>
			<img
				onClick={() => {
					setOpen(true)
				}}
				className="w-full h-full p-4 absolute top-[-.25rem] left-[-.25rem]"
				src={showPromotion?.popupImage}
				alt=""
			/>
			<Button
				onClick={() => {
					setShow(false)
				}}
				className="absolute top-[-.25rem] right-0 bg-red-400 w-8 h-8 rounded-full"
				variant="ghost"
				size="icon"
			>
				<X className="text-white" />
			</Button>
		</div>
	) : (
		<></>
	)
}
