import Logo from '@/assets/core/logo.svg'
import { useState } from 'react'
import Forgot from './Forgot/Forgot'
import SignIn from './SignIn/SignIn'
import SignUp from './SignUp/SignUp'

export default function Auth() {
	const [page, setPage] = useState<'signIn' | 'signUp' | 'forgot'>('signIn')

	return (
		<div
			style={{
				backgroundPositionY: '20%',
			}}
			className="flex flex-col justify-between bg-auth h-screen bg-cover"
		>
			<div className="flex items-center justify-center h-1/3">
				<img className="w-12 h-12" src={Logo} alt="Logo" />
				<p className="text-white font-bold text-[1.5rem]">Tell My Fortune</p>
			</div>

			<div className="h-2/3 px-16">
				{page === 'signIn' && <SignIn setPage={setPage} />}
				{page === 'signUp' && <SignUp setPage={setPage} />}
				{page === 'forgot' && <Forgot setPage={setPage} />}
			</div>
		</div>
	)
}
