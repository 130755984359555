import {
	get,
	getAll,
	getByField,
	insert,
	remove,
	update,
} from '@/firebase/crud'
import { app, auth } from '@/firebase/firebase'
import {
	getFromLocalStorage,
	removeFromLocalStorage,
	saveToLocalStorage,
} from '@/utils/cache'
import {
	WhereFilterOp,
	collection,
	getFirestore,
	query,
	where,
} from 'firebase/firestore'

export default class CoreEntity {
	collection = ''

	hook: any = null

	hasRealTime = false

	getCache = (key?: string) => {
		return getFromLocalStorage(key || this.collection)
	}

	setCache = (value: any, shouldUpdate = false, key?: string) => {
		const cache = this.getCache(key || this.collection)
		if (this.hasObject(cache) && !shouldUpdate) return
		saveToLocalStorage(key || this.collection, value)
	}

	clearCache = (key?: string) => {
		removeFromLocalStorage(key || this.collection)
	}

	find = async (collection: string, field: string, value: string) => {
		const { result, error } = await getByField(collection, field, value)
		return error || result
	}

	getHttp = async (id: string, collection?: string) => {
		const { result, error } = await get(collection || this.collection, id)
		return error || result
	}

	createRealTime = async ({
		search,
	}: {
		search?: {
			field: string
			operator: WhereFilterOp
			value: any
		}
	}) => {
		const db = getFirestore(app)
		let q = query(collection(db, this.collection))
		if (search) {
			q = query(
				collection(db, this.collection),
				where(search.field, search.operator, search.value),
			)
		}
		return q
	}

	getAll = async (collection?: string) => {
		const { result, error } = await getAll(collection || this.collection)
		return error || result
	}

	insert = async ({
		data,
		customId,
		collection,
	}: {
		data: any
		customId?: string
		collection?: string
	}) => {
		const { result, error } = await insert(
			collection || this.collection,
			data,
			customId,
		)

		return error || result
	}

	update = async (data: any, collection?: string) => {
		const { result, error } = await update(
			collection || this.collection,
			data.id,
			data,
		)
		return error || result
	}

	remove = async (id: string, collection?: string) => {
		const { result, error } = await remove(collection || this.collection, id)

		return error || result
	}

	// async upload(file: File, collection?: string) {
	// 	const { result, error } = await this.crud.upload(
	// 		collection || this.collection,
	// 		file,
	// 	)

	// 	return error || result
	// }

	// async deleteFile(name: string, collection?: string) {
	// 	const { result, error } = await this.crud.deleteFile(
	// 		collection || this.collection,
	// 		name,
	// 	)

	// 	return error || result
	// }

	setFind = async (
		shouldUpdate = true,
		field: string,
		value: string,
		collection?: string,
	) => {
		const cache = this.getCache()
		if (shouldUpdate || !this.hasObject(cache)) {
			const response = await this.find(
				collection || this.collection,
				field,
				value,
			)
			this.setCache(response, shouldUpdate, collection || this.collection)
			return response
		} else {
			return cache
		}
	}

	setClass = async (shouldUpdate = true, collection?: string) => {
		const cache = this.getCache()
		if (shouldUpdate || !this.hasObject(cache)) {
			const response = await this.getAll(collection || this.collection)
			this.setCache(response, shouldUpdate, collection || this.collection)
			return response
		} else {
			return cache
		}
	}

	setClassById = async ({
		id,
		shouldUpdate,
		collection,
		cachePath,
	}: {
		id: string
		shouldUpdate?: boolean
		collection?: string
		cachePath?: string
	}) => {
		const cache = this.getCache(cachePath || this.collection)
		if (shouldUpdate || !this.hasObject(cache)) {
			const response = await this.getHttp(id, collection || this.collection)
			this.setCache(response, shouldUpdate, cachePath || this.collection)
			return response
		} else {
			return cache
		}
	}

	willFetch = async ({
		path,
		config,
		basePath = this.collection,
	}: {
		path: string
		config: RequestInit
		basePath?: string
	}) => {
		const token = await auth.currentUser?.getIdToken()
		const response = await fetch(
			`${process.env.REACT_APP_ENVIRONMENT === 'development' ? process.env.REACT_APP_BACKEND_DEV + `/${basePath}/${path}` : process.env.REACT_APP_BACKEND + `/${basePath}/${path}`}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				...config,
			},
		)
		return await response.json()
	}

	hasObject(data: any) {
		if (!data) return false
		return Object.keys(data).length > 0
	}
}
