import { Button } from '@/components/ui/button'
import { ArrowLeft } from 'lucide-react'
import { useHistory } from 'react-router'

export default function Deposits() {
	const navigate = useHistory()
	return (
		<div className="p-8 space-y-4">
			<div className="flex items-center gap-2">
				<Button
					onClick={() => navigate.replace('/')}
					className="text-white w-auto"
					variant="ghostLight"
					size="icon"
				>
					<ArrowLeft />
				</Button>
				<p className="text-white font-bold">Histórico de Depósitos</p>
			</div>
			<div className="space-y-8">
				{[0, 1, 2, 3, 4, 5, 6].map(() => {
					return (
						<div>
							<div className="flex items-center justify-between">
								<p className="text-small text-white font-bold">
									Depósito - #012719082
								</p>
								<button type="button">
									<p className="text-extraSmall text-white">
										Preciso de ajuda com esse pedido
									</p>
								</button>
							</div>
							<p className="text-small text-primary-100">
								Data de Pagamento: 99/99/2099
							</p>
						</div>
					)
				})}
			</div>
		</div>
	)
}
