import { useQuestionContext } from '@/contexts/QuestionContext'
import HistoricBox from './HistoricBox'

export default function Historic() {
	const {
		hook: { questions },
	} = useQuestionContext()

	return (
		<div className="flex flex-col gap-4 pl-8 lg:pl-0 pb-8 lg:pb-0">
			<p className="text-white font-bold">Histórico:</p>
			<div className="flex lg:flex-col items-center gap-4 overflow-x-auto lg:overflow-x-hidden lg:overflow-y-auto overflow-y-hidden lg:max-h-[30rem]">
				{questions?.map((item, index) => {
					return <HistoricBox question={item} key={`historic_${index}`} />
				})}
			</div>
		</div>
	)
}
