import QuestionsEntity from '@/classes/questions/QuestionsClass'
import { setQuestions } from '@/classes/questions/actions/setQuestions'
import React, { useContext, useEffect } from 'react'
import { useUserContext } from './UserContext'

const QuestionContext = React.createContext({} as QuestionsEntity)

export function QuestionContextProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const entity = new QuestionsEntity()

	const {
		hook: { firebaseUser, user },
	} = useUserContext()

	useEffect(() => {
		if (!firebaseUser) return
		setQuestions(entity, firebaseUser.uid)
	}, [firebaseUser, user])

	return (
		<QuestionContext.Provider value={entity}>
			{children}
		</QuestionContext.Provider>
	)
}

export const useQuestionContext = () => {
	const context = useContext(QuestionContext)

	return context
}
