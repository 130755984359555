import PaymentsEntity from '../PaymentsClass'
import { IPayments } from '../interface/payments'

export async function setPayments(entity: PaymentsEntity, userId: string) {
	if (!userId) return

	try {
		const res = await entity.setFind(true, 'userId', userId)

		if (!res) return []

		const ordered = res.sort((a: IPayments, b: IPayments) => {
			// Parse the string format 'DD/MM/YYYY, HH:mm:ss' into a valid date
			const dateA = new Date(a.createdAt).getTime()
			const dateB = new Date(b.createdAt).getTime()

			// Sort in descending order (most recent first)
			return dateB - dateA
		})
		entity.hook.setPayments(ordered) // Update the entity with ordered questions
	} catch (error) {
		console.error('Error setting payments: ', error)
	}
}
