import { CardsType } from '@/data/cards/CardsType'
import { cn } from '@/lib/utils'

export default function FortuneCard({
	cardNumber,
	cardSet,
	showingCard = false,
	currentCard = false,
	className = '',
	onClick,
	label,
}: {
	cardNumber: number
	cardSet: CardsType[]
	showingCard?: boolean
	currentCard?: boolean
	className?: string
	onClick?: () => void
	label?: boolean
}) {
	return (
		cardSet[cardNumber] && (
			<div
				data-large={showingCard && currentCard}
				data-small={showingCard && !currentCard}
				className={cn(
					'flex-1 ease-in-out group duration-300 transition-all data-[large=true]:flex-[0.5] data-[small=true]:flex-[0.25] flex flex-col',
					className,
				)}
			>
				{label && (
					<p className="text-white mx-auto text-center text-small font-bold mb-2">
						{cardSet[cardNumber].name}
					</p>
				)}
				<div
					className="w-full h-60 lg:h-[20rem] border group-data-[small=true]:opacity-20 bg-black/50 border-secondary rounded-[10px] fortune-card"
					onClick={() => {
						onClick && onClick()
						if (showingCard && currentCard) {
							// TODO fullscreen
						}
					}}
					style={{
						backgroundImage: `url(${cardSet[cardNumber].image})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
					}}
				/>
			</div>
		)
	)
}
