import QuestionsEntity from '../QuestionsClass'
import { IQuestion } from '../interface/question'

export async function setQuestions(entity: QuestionsEntity, userId: string) {
	if (!userId) return

	try {
		const res = await entity.setFind(true, 'userId', userId)

		if (!res) return []

		const ordered = res.sort((a: IQuestion, b: IQuestion) => {
			// Parse the string format 'DD/MM/YYYY, HH:mm:ss' into a valid date
			const dateA = parseDate(a.createdAt)
			const dateB = parseDate(b.createdAt)

			// Sort in descending order (most recent first)
			return dateB - dateA
		})

		entity.hook.setQuestions(ordered) // Update the entity with ordered questions
	} catch (error) {
		console.error('Error setting questions: ', error)
	}
}

// Function to parse 'DD/MM/YYYY, HH:mm:ss' format into a valid timestamp
function parseDate(dateStr: string): number {
	const [datePart, timePart] = dateStr.split(', ')
	const [day, month, year] = datePart.split('/')
	const [hour, minute, second] = timePart.split(':')

	// Create a Date object with the parsed values
	return new Date(
		Number(year),
		Number(month) - 1, // Month is zero-indexed in JavaScript
		Number(day),
		Number(hour),
		Number(minute),
		Number(second),
	).getTime() // Convert to timestamp for comparison
}
