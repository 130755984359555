import UserEntity from "@/classes/user/UserClass";
import { addMonthly } from "@/classes/user/actions/promotions/add/addMonthly";
import { addOnce } from "@/classes/user/actions/promotions/add/addOnce";
import { addWeekly } from "@/classes/user/actions/promotions/add/addWeekly";

export interface StoreType {
  priceId?: string;
  amount: number;
  name: string;
  price: string;
  promotion: boolean;
  type: "coin" | "touch";
  isFree: boolean;
  once?: boolean;
  week?: boolean;
  month?: boolean;
  promotionId?: string;
  time?: number;
  onclick?: (entity: UserEntity) => Promise<void>;
}

export const StoreData: StoreType[] = [
  {
    priceId: "price_1QPDYiAgRNYgDKJ1EnHUwOiW",
    amount: 3,
    name: "Moedas da Sorte",
    price: "2.90",
    promotion: false,
    type: "coin",
    isFree: false,
  },
  {
    priceId: "price_1QPDYzAgRNYgDKJ1TRBwLNzl",
    amount: 7,
    name: "Moedas da Sorte",
    price: "6.49",
    promotion: false,
    type: "coin",
    isFree: false,
  },
  {
    priceId: "price_1QPDZDAgRNYgDKJ1rjZmqFGU",
    amount: 11,
    name: "Moedas da Sorte",
    price: "9.90",
    promotion: false,
    type: "coin",
    isFree: false,
  },
  {
    priceId: "price_1QPDZQAgRNYgDKJ1JfsC0yFw",
    amount: 26,
    name: "Moedas da Sorte",
    price: "24.90",
    promotion: false,
    type: "coin",
    isFree: false,
  },
  {
    priceId: "price_1QPDZcAgRNYgDKJ13h9OHTH1",
    amount: 55,
    name: "Moedas da Sorte",
    price: "44.90",
    promotion: false,
    type: "coin",
    isFree: false,
  },
  {
    priceId: "price_1QPDZnAgRNYgDKJ1pYjWYuSp",
    amount: 100,
    name: "Moedas da Sorte",
    price: "89.90",
    promotion: false,
    type: "coin",
    isFree: false,
  },
  {
    amount: 1,
    name: "Toque do Destino",
    price: "1a vez Grátis",
    promotion: true,
    type: "touch",
    isFree: true,
    once: true,
    promotionId: "touch1",
    onclick: addOnce,
  },
  {
    amount: 1,
    name: "Toque do Destino",
    price: "Grátis Semanal",
    promotion: true,
    type: "touch",
    isFree: true,
    week: true,
    promotionId: "touchWeekly",
    time: 24 * 7,
    onclick: addWeekly,
  },
  {
    amount: 3,
    name: "Moedas da Sorte",
    price: "Grátis Mensal",
    promotion: true,
    type: "coin",
    isFree: true,
    month: true,
    promotionId: "coinMonthly",
    time: 24 * 30,
    onclick: addMonthly,
  },
];
