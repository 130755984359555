import { callChatGPTAPI } from '@/actions/chatGpt'
import { renderFormattedSentences } from '@/utils/cards'
import { RandomNumbers } from '@/utils/randomNumbers'
import { v4 as uuidv4 } from 'uuid'
import QuestionsEntity from '../QuestionsClass'

export async function makeQuestion(
	userId: string,
	entity: QuestionsEntity,
	body: {
		question: string
		destinyTouch: boolean
	},
) {
	const uuid = uuidv4()
	const randomNumbers = RandomNumbers().getRandomNumbers(0, 11, 3)

	const fields = [
		'kassivo1',
		'kassivo2',
		'kassivo3',
		'kativo4',
		'kativo5',
		'kativo6',
	]

	const phrases = fields.map((field: any) =>
		renderFormattedSentences(
			field,
			randomNumbers[0],
			randomNumbers[1],
			randomNumbers[2],
		),
	)

	const promises = phrases.map((phrase: string) =>
		callChatGPTAPI(
			'Você é um oráculo e precisa corrigir gramaticalmente a frase: ' + phrase,
		),
	)
	const responses = await (await Promise.all(promises)).map(
		(response) => response.message.content,
	)

	const kassivo1 = responses[0]
	const kassivo2 = responses[1]
	const kassivo3 = responses[2]
	const kativo4 = responses[3]
	const kativo5 = responses[4]
	const kativo6 = responses[5]

	const promisesInterpretation = responses.map((phrase: string) =>
		callChatGPTAPI(
			'Você é um oráculo e precisa interpretar essa frase: ' + phrase,
		),
	)
	const responsesInterpretation = await (
		await Promise.all(promisesInterpretation)
	).map((response) => response.message.content)

	const kassivo1Intepretation = responsesInterpretation[0]
	const kassivo2Intepretation = responsesInterpretation[1]
	const kassivo3Intepretation = responsesInterpretation[2]
	const kativo4Intepretation = responsesInterpretation[3]
	const kativo5Intepretation = responsesInterpretation[4]
	const kativo6Intepretation = responsesInterpretation[5]

	const data = {
		...body,
		id: uuid,
		userId,
		numbers: randomNumbers,
		createdAt: new Date().toLocaleString(),
		kassivo1,
		kassivo2,
		kassivo3,
		kativo4,
		kativo5,
		kativo6,
		kassivo1Intepretation,
		kassivo2Intepretation,
		kassivo3Intepretation,
		kativo4Intepretation,
		kativo5Intepretation,
		kativo6Intepretation,
	}
	await entity.insert({
		data,
		customId: uuid,
	})
	return data
}
