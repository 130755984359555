export async function callChatGPTAPI(prompt: string) {
	const response = await fetch(
		`${process.env.REACT_APP_BACKEND_URL}/gpt/completion`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				prompt: prompt,
			}),
		},
	)

	const data = await response.json()
	return data
}
