import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { ForgotFormSchema, ForgotSchema } from './schema'

export default function ForgotForm() {
	const formMethods = useForm<ForgotFormSchema>({
		resolver: zodResolver(ForgotSchema),
	})

	return {
		formMethods,
	}
}
