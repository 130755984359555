import UserEntity from '../../../UserClass'
import { setUser } from '../../userData/setUser'

export const addWeekly = async (entity: UserEntity) => {
	const userPromotions = entity.hook.user.promotions || []
	if (userPromotions.includes('touchWeekly')) return
	await entity.update({
		id: entity.hook.firebaseUser?.uid,
		touch: entity.hook.user.touch + 1,
		promotions: [...userPromotions, 'touchWeekly'],
		touchWeekly: new Date().toLocaleString(),
	})
	await setUser(entity)
}
