import UserEntity from '@/classes/user/UserClass'
import { PromotionsData } from '@/data/promotions/promotionsData'
import { differenceInHours } from 'date-fns'

export const verifyFirstWeek = (entity: UserEntity, userData?: any) => {
	const user = userData || entity.hook.user
	const promotions = user.promotions || []
	const hasPromotion = promotions.includes('firstWeek')

	// Get current time and createdAt time
	const currentTime = new Date()
	const createdAtTime = new Date(user.createdAt)

	// Calculate the difference in hours using date-fns
	const hoursDifference = differenceInHours(currentTime, createdAtTime)

	// Check if the difference is within 168 hours (7 days)
	const isWithinTime = hoursDifference <= 24 * 7

	return {
		hasPromotion,
		isWithinTime,
		promotion: PromotionsData.find(
			(promotion) => promotion.promotionId === 'firstWeek',
		),
	}
}
