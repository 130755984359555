import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import React from 'react'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Theme variables */
import { ToastContainer } from 'react-toastify'
import ContextWrapper from './contexts/ContextWrapper'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import 'react-toastify/dist/ReactToastify.css'
import Menu from './components/SideMenu'
import Routing from './routes/Routes'

import AppUrlListener from './components/AppUrlListener'
import './theme/menu.css'
import './theme/variables.css'

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

if (!STRIPE_PUBLISHABLE_KEY) {
	throw new Error('Missing Stripe Publishable Key')
}

export const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

setupIonicReact()

const App: React.FC = () => (
	<IonApp>
		<IonReactRouter>
			<AppUrlListener></AppUrlListener>
			<ContextWrapper>
				<Elements
					stripe={stripePromise}
					options={{
						appearance: {
							theme: 'night',
						},
						mode: 'setup',
						currency: 'brl',
					}}
				>
					{/* <IonSplitPane contentId="main"> */}
					<Menu />

					<div className="h-screen w-full" id="main">
						<IonRouterOutlet>
							<Routing />
						</IonRouterOutlet>
					</div>
					{/* </IonSplitPane> */}
				</Elements>
			</ContextWrapper>
		</IonReactRouter>
		<ToastContainer
			limit={1}
			autoClose={1000 * 5 /* 2 seconds */}
			hideProgressBar
			position="top-right"
		/>
	</IonApp>
)

export default App
