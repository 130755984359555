import { useMediaQuery } from 'usehooks-ts'
import DesktopHome from './screens/DesktopHome'
import MobileHome from './screens/MobileHome'

export default function Home() {
	const isLg = useMediaQuery('(min-width: 1024px)')

	return (
		<>
			{isLg && <DesktopHome />}

			{!isLg && <MobileHome />}
		</>
	)
}
