import PaymentsEntity from '@/classes/payments/PaymentsClass'
import { setPayments } from '@/classes/payments/actions/setPayments'
import React, { useContext, useEffect } from 'react'
import { useUserContext } from './UserContext'

const PaymentsContext = React.createContext({} as PaymentsEntity)

export function PaymentsContextProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const entity = new PaymentsEntity()

	const {
		hook: { firebaseUser, user },
	} = useUserContext()

	useEffect(() => {
		if (!firebaseUser) return
		setPayments(entity, firebaseUser.uid)
	}, [firebaseUser, user])

	return (
		<PaymentsContext.Provider value={entity}>
			{children}
		</PaymentsContext.Provider>
	)
}

export const usePaymentsContext = () => {
	const context = useContext(PaymentsContext)

	return context
}
