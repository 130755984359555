import SuccessSVG from '@/assets/icons/success.svg'
import { setUser } from '@/classes/user/actions/userData/setUser'
import { Button } from '@/components/ui/button'
import { useUserContext } from '@/contexts/UserContext'
import { ArrowLeft, Coins } from 'lucide-react'
import { useEffect } from 'react'
import { PiCloverLight } from 'react-icons/pi'
import { useHistory } from 'react-router'

export default function Success() {
	const userEntity = useUserContext()
	const {
		hook: { user },
	} = userEntity
	useEffect(() => {
		setUser(userEntity)
	}, [])

	const navigate = useHistory()

	return (
		<div className="flex flex-col items-center justify-between space-y-8 p-8 h-[calc(100vh-6rem)]">
			<div className="flex items-center justify-between w-full">
				<div className="flex items-center gap-2">
					<Button
						onClick={() => navigate.replace('/')}
						className="text-white w-auto"
						variant="ghostLight"
						size="icon"
					>
						<ArrowLeft />
					</Button>
					<p className="text-white font-bold">Parabéns!</p>
				</div>
				<div className="flex items-center gap-4">
					<div className="flex items-center gap-2">
						<PiCloverLight className="text-secondary w-6 h-6" />
						<p className="text-white font-bold text-small">{user.touch || 0}</p>
					</div>

					<div className="flex items-center gap-2">
						<Coins className="text-secondary" />
						<p className="text-white font-bold text-small">{user.coins || 0}</p>
					</div>
				</div>
			</div>

			<div>
				<p className="text-white">
					Você adquiriu com sucesso suas moedas! Volte para a página inicial
					para fazer as suas consultas.
				</p>
				<img src={SuccessSVG} alt="" className="w-[25rem] h-[25rem]" />
			</div>
		</div>
	)
}
