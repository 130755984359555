import { handleBuyCoins } from '@/classes/user/actions/buy/buyCoins'
import { handleBuyMix } from '@/classes/user/actions/buy/buyMix.ts'
import { useUserContext } from '@/contexts/UserContext'
import { getTimeLeft } from '@/utils/date'
import { InAppBrowser } from '@capgo/inappbrowser'
import { useEffect, useState } from 'react'
import { useCountdown } from 'usehooks-ts'
import { Button } from './ui/button'
import { Dialog, DialogContent } from './ui/dialog'

export default function PromotionModal({
	open,
	setOpen,
}: {
	open: boolean
	setOpen: (bool: boolean) => void
}) {
	const userEntity = useUserContext()
	const {
		hook: { user, showPromotion, setShowPromotion },
	} = userEntity

	const [count, { startCountdown, stopCountdown }] = useCountdown({
		countStart: getTimeLeft(true, showPromotion!, userEntity, user.createdAt)
			.expiresIn,
		intervalMs: 1000,
		countStop: 0,
	})

	const [timeLeft, setTimeLeft] = useState(
		getTimeLeft(true, showPromotion!, userEntity, user.createdAt).text,
	)

	useEffect(() => {
		stopCountdown()
		startCountdown()
	}, [userEntity.hook.user])

	useEffect(() => {
		if (count <= 0) {
			stopCountdown()
			setOpen(false)
			setShowPromotion(null)
		} else
			setTimeLeft(
				getTimeLeft(true, showPromotion!, userEntity, user.createdAt).text,
			)
	}, [count])

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		InAppBrowser.addListener('urlChangeEvent', () => {
			setOpen(false)
			console.log('Browser closed.')
		})
	}, [])

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="w-4/5 p-0 rounded-[10px]">
				<div className="flex flex-col p-[.15rem] space-y-4">
					<div className="bg-secondary-500 p-2 rounded-t-[10px] text-primary text-[1.5rem] font-bold text-center">
						{showPromotion?.name}
					</div>
					<div className="bg-white space-y-4">
						<div className="p-2 mx-4 bg-primary rounded-[10px] text-white">
							<p className="text-white font-bold text-center">
								Tempo até acabar - {timeLeft}
							</p>
						</div>
						<img className="px-2" src={showPromotion?.modalImage} alt="" />
					</div>
					<div className="bg-secondary-500 p-2 rounded-b-[10px] text-center">
						<Button
							disabled={count <= 0}
							loading={loading}
							onClick={async () => {
								const isMix = showPromotion?.type === 'mix'
								if (isMix) {
									await handleBuyMix({
										setLoading,
										data: {
											coins: showPromotion?.coin,
											touch: showPromotion?.touch,
											priceId: showPromotion?.priceId!,
										},
										userId: userEntity.hook.firebaseUser?.uid || '',
										firstBuy: false,
										promotionalBuy: true,
										promotionCode: showPromotion?.promotionId,
										userEntity,
									})
								} else if (!isMix) {
									await handleBuyCoins({
										setLoading,
										data: {
											coins: showPromotion?.coin || 0,
											priceId: showPromotion?.priceId!,
										},
										userId: userEntity.hook.firebaseUser?.uid || '',
										firstBuy: false,
										promotionalBuy: true,
										promotionCode: showPromotion?.promotionId,
										userEntity,
									})
								}
							}}
							className="text-primary text-[1.5rem] font-bold text-center"
						>
							COMPRE AGORA!
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}
