import { cn } from '@/lib/utils'

export default function LowerThird({
	image,
	className,
}: {
	image: string
	className?: string
}) {
	return (
		<div
			className={cn(
				'bg-secondary flex items-start gap-4 min-h-24 p-4 rounded-[10px] lg:mx-8 mt-8',
				className,
			)}
		>
			<img className="w-full h-full" src={image} alt="" />
		</div>
	)
}
