import Coins from '@/assets/icons/coins.svg'
import Stars from '@/assets/icons/stars.svg'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useUserContext } from '@/contexts/UserContext'
import ConsultationForm from './form/form'
import { ConsultationFormSchema } from './form/schema'
import useConsultationHook from './useConsultationHook'

export default function Consultation() {
	const {
		hook: { user },
	} = useUserContext()

	const { handleMakeQuestion, handleDestinyTouch } = useConsultationHook()

	const {
		formMethods: {
			register,
			watch,
			handleSubmit,
			formState: { isSubmitting },
		},
	} = ConsultationForm()

	const submit = async (formData: ConsultationFormSchema) => {
		if (formData.question) {
			await handleMakeQuestion(formData.question)
		} else {
			await handleDestinyTouch()
		}
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<h2 className="text-white font-bold">Realizar nova consulta:</h2>

				<div className="flex items-center gap-4">
					<div className="flex items-center gap-2">
						<img src={Stars} alt="" />
						<p className="text-white font-bold text-small">
							{user?.touch || 0}
						</p>
					</div>

					<div className="flex items-center gap-2">
						<img src={Coins} alt="" />
						<p className="text-white font-bold text-small">
							{user?.coins || 0}
						</p>
					</div>
				</div>
			</div>
			<form onSubmit={handleSubmit(submit)} className="w-full">
				<div className="w-full rounded-[10px] bg-primary-900 flex flex-col">
					<div className="flex-1 flex items-center justify-center">
						<Input
							{...register('question')}
							placeholder="Exemplo: Como eu posso melhorar minha sorte?"
							className="bg-transparent rounded-[10px] text-white w-full border-none focus-visible:ring-0 placeholder:text-secondary-100 ring-0 focus-visible:ring-offset-0"
						/>
					</div>
					<div className="flex-1 flex items-center justify-between">
						<Button
							disabled={
								!user?.coins ||
								user?.coins === 0 ||
								!watch('question') ||
								watch('question')!.length < 10
							}
							loading={
								!!watch('question') &&
								watch('question')!.length > 10 &&
								isSubmitting
							}
							className="flex-[0.7] rounded-none rounded-bl-[10px]"
						>
							Enviar pergunta
						</Button>
						<Button
							loading={
								(!watch('question') || watch('question')!.length < 10) &&
								isSubmitting
							}
							disabled={
								!user?.touch ||
								user?.touch === 0 ||
								(!!watch('question') && watch('question')!.length > 10)
							}
							className="flex-[0.3] rounded-none rounded-br-[10px] bg-white"
						>
							Toque do destino
						</Button>
					</div>
				</div>
			</form>
		</div>
	)
}
