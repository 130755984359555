import { IQuestion } from '@/classes/questions/interface/question'
import { Button } from '@/components/ui/button'
import { useQuestionContext } from '@/contexts/QuestionContext'
import { groupQuestionsByDate } from '@/utils/cards'
import { ArrowLeft } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import DestinyTouch from '../home/sections/destinyTouch/DestinyTouch'

export default function ConsultationHistory() {
	const {
		hook: { questions },
	} = useQuestionContext()
	const navigate = useHistory()
	const [questionsByDate, setQuestionsByDate] = useState<IQuestion[]>()

	useEffect(() => {
		if (!questions || questions.length === 0) return
		setQuestionsByDate(groupQuestionsByDate(questions))
	}, [questions])

	return (
		<div className="space-y-4 p-8">
			<div className="flex items-center gap-2">
				<Button
					onClick={() => navigate.replace('/')}
					className="text-white w-auto"
					variant="ghostLight"
					size="icon"
				>
					<ArrowLeft />
				</Button>
				<p className="text-white font-bold">Histórico de Consultas:</p>
			</div>

			{questionsByDate &&
				Object.keys(questionsByDate).map((key: any) => {
					return (
						<div className="space-y-4">
							<p className="text-white">{key}</p>
							<div className="space-y-4 lg:grid lg:grid-cols-3 lg:gap-4 lg:align-top lg:space-y-0">
								{Object.values(questionsByDate[key]).map(
									(question: IQuestion) => {
										return (
											<DestinyTouch
												key={`each_question_${question.id}`}
												question={question}
											/>
										)
									},
								)}
							</div>
						</div>
					)
				})}
		</div>
	)
}
