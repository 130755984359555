import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { signIn } from '@/firebase/auth'
import SignInForm from './form/form'
import { SignInFormSchema } from './form/schema'

export default function SignIn({
	setPage,
}: {
	setPage: (page: 'signIn' | 'signUp' | 'forgot') => void
}) {
	const { formMethods } = SignInForm()

	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods

	const submit = async (formData: SignInFormSchema) => {
		await signIn(formData.email, formData.password)
	}

	return (
		<div className="flex flex-col items-center gap-4">
			<p className="text-white font-bold">Login:</p>
			<form className="w-full xl:w-1/3" onSubmit={handleSubmit(submit)}>
				<Input
					{...register('email')}
					placeholder="johndoe@gmail.com"
					className="mt-4"
				/>
				<Input
					{...register('password')}
					type="password"
					placeholder="********"
					className="my-4"
				/>
				<Button type="submit" loading={isSubmitting} className="w-full">
					Entrar
				</Button>
			</form>
			<div className="flex items-center justify-between">
				<Button
					disabled={isSubmitting}
					onClick={() => {
						setPage('forgot')
					}}
					variant="ghostLight"
				>
					Esqueci minha senha
				</Button>
				<Button
					disabled={isSubmitting}
					onClick={() => {
						setPage('signUp')
					}}
					variant="ghost"
				>
					Criar nova conta
				</Button>
			</div>
		</div>
	)
}
