import { MESSAGING_TOPICS } from '@/utils/messaging_topics'
import { Toast } from '@/utils/toast'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { Capacitor } from '@capacitor/core'

export default function Messaging() {
	const checkPermissions = async () => {
		const result = await FirebaseMessaging.checkPermissions()
		if (result.receive !== 'granted') {
			requestPermission()
		}
		return result.receive
	}

	const getDeliveredNotifications = async () => {
		const result = await FirebaseMessaging.getDeliveredNotifications()
		return result.notifications
	}

	const removeAllDeliveredNotifications = async () => {
		await FirebaseMessaging.removeAllDeliveredNotifications()
	}

	const subscribeToTopic = async (topic: string) => {
		await FirebaseMessaging.subscribeToTopic({ topic })
	}

	const unsubscribeFromTopic = async (topic: string) => {
		await FirebaseMessaging.unsubscribeFromTopic({ topic })
	}

	const addTokenReceivedListener = async () => {
		await FirebaseMessaging.addListener('tokenReceived', (event: any) => {
			console.log('tokenReceived', { event })
		})
	}

	const addNotificationReceivedListener = async () => {
		await FirebaseMessaging.addListener(
			'notificationReceived',
			(event: any) => {
				console.log('notificationReceived', { event })
				Toast.showInfo(
					event.notification.body || event.notification.title || '',
				)
			},
		)
	}

	const addNotificationActionPerformedListener = async () => {
		await FirebaseMessaging.addListener(
			'notificationActionPerformed',
			(event: any) => {
				console.log('notificationActionPerformed', { event })
			},
		)
	}

	const removeAllListeners = async () => {
		await FirebaseMessaging.removeAllListeners()
	}
	const requestPermission = async () => {
		await FirebaseMessaging.requestPermissions()
	}

	const getToken = async () => {
		const { token } = await FirebaseMessaging.getToken()
		return token
	}

	const init = async (
		notificationsTopics: Array<{
			subscribed: boolean
			topic: string
			translate: string
		}>,
	) => {
		if (Capacitor.getPlatform() === 'web') return

		const granted = await checkPermissions()
		if (!granted || granted !== 'granted') requestPermission()

		addNotificationReceivedListener()

		const token = await getToken()

		initSubscriptions(notificationsTopics)
		return token
	}

	const initSubscriptions = (notificationsTopics: any) => {
		if (Capacitor.getPlatform() === 'web') return
		notificationsTopics.forEach((topic: any) => {
			if (topic.subscribed) {
				subscribeToTopic(topic.topic)
			} else {
				unsubscribeFromTopic(topic.topic)
			}
		})
	}

	const unsubscribeAll = () => {
		MESSAGING_TOPICS.forEach((topic: any) => {
			unsubscribeFromTopic(topic.topic)
		})
	}

	return {
		MESSAGING_TOPICS,
		requestPermission,
		getToken,
		checkPermissions,
		getDeliveredNotifications,
		removeAllDeliveredNotifications,
		subscribeToTopic,
		unsubscribeFromTopic,
		addTokenReceivedListener,
		addNotificationReceivedListener,
		addNotificationActionPerformedListener,
		removeAllListeners,
		init,
		initSubscriptions,
		unsubscribeAll,
	}
}
