import { PaymentsContextProvider } from './PaymentsContext'
import { QuestionContextProvider } from './QuestionContext'
import { UserContextProvider } from './UserContext'

export default function ContextWrapper({
	children,
}: {
	children: React.ReactNode
}) {
	return (
		<UserContextProvider>
			<QuestionContextProvider>
				<PaymentsContextProvider>{children}</PaymentsContextProvider>
			</QuestionContextProvider>
		</UserContextProvider>
	)
}
