import FortuneCard from '@/components/FortuneCard'
import { Button } from '@/components/ui/button'
import { useQuestionContext } from '@/contexts/QuestionContext'
import { CardsData0, CardsData1, CardsData2 } from '@/data/cards/CardsData'
import { ArrowDown, ArrowUp } from 'lucide-react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { useMediaQuery } from 'usehooks-ts'

export default function LastConsultation() {
	const [currentCard, setCurrentCard] = useState<number>(0)

	const {
		hook: { questions, setCurrentQuestion },
	} = useQuestionContext()

	const navigate = useHistory()

	const isLg = useMediaQuery('(min-width: 1024px)')

	const [index, setIndex] = useState(0)

	const changeQuestion = (index: number) => {
		if (!questions || questions.length === 0) return
		if (index >= 0 && index < questions.length) {
			setIndex(index)
		} else if (index < 0) {
			setIndex(questions.length - 1)
		} else {
			setIndex(0)
		}
	}

	return (
		<div className="flex flex-col gap-4">
			<h2 className="text-white font-bold">Última consulta:</h2>
			{questions && questions?.length > 0 && (
				<>
					{isLg && (
						<p className="text-secondary font-bold text-large mb-4 w-full">
							{questions[index].destinyTouch
								? 'Toque do destino'
								: questions[index].question}
						</p>
					)}
					<div className="flex items-center gap-4 lg:gap-12 w-full">
						<FortuneCard
							cardNumber={questions[index].numbers[0]}
							cardSet={CardsData0}
							showingCard={currentCard > 0}
							currentCard={currentCard === 1}
							onClick={() => {
								if (currentCard === 1) setCurrentCard(0)
								else setCurrentCard(1)
							}}
						/>
						<FortuneCard
							cardNumber={questions[index].numbers[1]}
							cardSet={CardsData1}
							showingCard={currentCard > 0}
							currentCard={currentCard === 2}
							onClick={() => {
								if (currentCard === 2) setCurrentCard(0)
								else setCurrentCard(2)
							}}
						/>
						<FortuneCard
							cardNumber={questions[index].numbers[2]}
							cardSet={CardsData2}
							showingCard={currentCard > 0}
							currentCard={currentCard === 3}
							onClick={() => {
								if (currentCard === 3) setCurrentCard(0)
								else setCurrentCard(3)
							}}
						/>
					</div>
					{isLg && (
						<div className="flex flex-col gap-8 justify-between">
							<div className="space-y-4">
								<p className="text-secondary font-bold">Karma ativo</p>
								<p className="text-white text-small">
									{questions[index].kativo4}
									<br /> <br />
									{questions[index].kativo5}
									<br /> <br />
									{questions[index].kativo6}
								</p>
							</div>
							<div className="flex items-end justify-between">
								<Button
									onClick={() => {
										setCurrentQuestion(questions[index])
										navigate.push('/details')
									}}
									variant="default"
									className="h-12 text-large"
								>
									Ver consulta completa
								</Button>
								<div className="flex items-center gap-2">
									<div className="flex flex-col items-center gap-2">
										<p className="text-[#C1AED1] text-small">Anterior</p>
										<Button
											disabled={index === questions.length - 1}
											onClick={() => {
												changeQuestion(index + 1)
											}}
											variant="default"
											size="icon"
										>
											<ArrowDown className="text-[#1F0437]" />
										</Button>
									</div>
									<div className="flex flex-col items-center gap-2">
										<p className="text-[#C1AED1] text-small">+ Nova</p>
										<Button
											disabled={index === 0}
											onClick={() => {
												changeQuestion(index - 1)
											}}
											variant="default"
											size="icon"
										>
											<ArrowUp className="text-[#1F0437]" />
										</Button>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	)
}
