import { addMilliseconds, isAfter, parseISO } from 'date-fns'

interface LocalStorageItem<T> {
	expiresIn: string
	data: T
}

export function getFromLocalStorage<T>(key: string): T | null {
	const stored = localStorage.getItem(key)

	if (!stored) {
		return null
	}

	const parsed: LocalStorageItem<T> = JSON.parse(stored)

	if (isAfter(new Date(), parseISO(parsed.expiresIn))) {
		localStorage.removeItem(key)
		return null
	}

	return parsed.data
}

export function saveToLocalStorage<T = unknown>(
	key: string,
	data: T,
	staleTime = 1000 * 60 * 60 * 24 * 7, // 7 days,
) {
	const expiresIn = addMilliseconds(new Date(), staleTime)
	localStorage.setItem(key, JSON.stringify({ expiresIn, data }))
}

export function removeFromLocalStorage(key: string) {
	localStorage.removeItem(key)
}

export function clearLocalStorage() {
	localStorage.clear()
}
