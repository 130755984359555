import { keys } from '@/configs/keys'
import { PromotionsType } from '@/data/promotions/promotionsData'
import { auth } from '@/firebase/firebase'
import {
	getFromLocalStorage,
	removeFromLocalStorage,
	saveToLocalStorage,
} from '@/utils/cache'
import { User } from 'firebase/auth'
import { useEffect, useState } from 'react'

export default function useUserHook() {
	//States
	const [user, setUser] = useState<any | null>(
		getFromLocalStorage<any>(keys.currentUser) || null,
	)

	const [firebaseUser, setFirebaseUser] = useState<User | null>(null)

	const [showPromotion, setShowPromotion] = useState<PromotionsType | null>(
		null,
	)

	// Actions
	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			setFirebaseUser(user)
			if (user) saveToLocalStorage(keys.currentFirebaseUser, user)
			else {
				setUser(null)
				setFirebaseUser(null)
				setShowPromotion(null)
				removeFromLocalStorage(keys.currentFirebaseUser)
			}
		})
	}, [])

	return {
		user,
		setUser,
		firebaseUser,
		showPromotion,
		setShowPromotion,
	}
}
