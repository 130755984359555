import UserEntity from '../../../UserClass'
import { setUser } from '../../userData/setUser'

export const addOnce = async (entity: UserEntity) => {
	const userPromotions = entity.hook.user.promotions || []
	if (userPromotions.includes('touch1')) return
	await entity.update({
		id: entity.hook.firebaseUser?.uid,
		touch: entity.hook.user.touch + 1,
		promotions: [...userPromotions, 'touch1'],
		touch1: new Date().toLocaleString(),
	})
	await setUser(entity)
}
