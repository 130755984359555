import {
	createUserWithEmailAndPassword,
	deleteUser,
	sendPasswordResetEmail,
	signInAnonymously,
	signInWithEmailAndPassword,
} from 'firebase/auth'
import { keys } from '../configs/keys'
import { clearLocalStorage, saveToLocalStorage } from '../utils/cache'
import { Toast } from '../utils/toast'
import { verifyErrors } from './errors'
import { auth } from './firebase'

async function signIn(email: string, password: string) {
	let result = null
	let error = null
	try {
		result = await signInWithEmailAndPassword(auth, email, password)
		clearLocalStorage()
		Toast.showSucess('Bem Vindo!')
	} catch (e: any) {
		console.log('error - ', e)
		Toast.showError(verifyErrors(e.code))
		error = e
	}

	if (result?.user) {
		saveToLocalStorage(keys.currentUser, result.user.uid)
	}

	return { result, error }
}

async function exclude() {
	let result
	let error = null
	try {
		if (auth.currentUser) result = await deleteUser(auth.currentUser)
	} catch (e) {
		error = e
	}

	clearLocalStorage()

	return { result, error }
}

async function signInAnon() {
	let result
	let error = null
	try {
		result = await signInAnonymously(auth)
		clearLocalStorage()
	} catch (e) {
		error = e
	}

	if (result?.user) {
		saveToLocalStorage(keys.currentUser, result.user.uid)
	}

	return { result, error }
}

async function signUp(
	email: string,
	password: string,
	confirmPassword: string,
) {
	let result
	let error = null
	if (password !== confirmPassword) {
		Toast.showError('Senhas não conferem')
		return
	}
	try {
		result = await createUserWithEmailAndPassword(auth, email, password)
		clearLocalStorage()
	} catch (e: any) {
		Toast.showError(verifyErrors(e.code))
		error = e
	}

	if (result?.user) {
		saveToLocalStorage(keys.currentUser, result.user.uid)
	}

	return { result, error }
}

async function signOut() {
	let result
	let error = null
	try {
		result = await auth.signOut()
		clearLocalStorage()
	} catch (e) {
		error = e
	}

	return { result, error }
}

async function forgotPassword(email: string) {
	let result
	let error = null
	try {
		result = await sendPasswordResetEmail(auth, email)
	} catch (e) {
		error = e
	}

	return { result, error }
}

export { exclude, forgotPassword, signIn, signInAnon, signOut, signUp }
