import UserEntity from '@/classes/user/UserClass'
import { PromotionsType } from '@/data/promotions/promotionsData'
import { StoreType } from '@/pages/logged/shop/StoreData'
import { addDays } from 'date-fns'

export function createDateFromString(dateString: string) {
	// Split date and time
	const [datePart, timePart] = dateString.split(', ')

	// Split day, month, and year
	const [day, month, year] = datePart.split('/').map(Number) // map(Number) converts the strings to numbers

	// Split hours, minutes, and seconds
	const [hours, minutes, seconds] = timePart.split(':').map(Number)

	// Create new Date object
	const date = new Date(year, month - 1, day, hours, minutes, seconds)

	return date
}

export const getTimeLeft = (
	hasUsedPromotion: boolean,
	data: StoreType | PromotionsType,
	userEntity: UserEntity,
	startDate?: string,
): {
	text: string
	expiresIn: number
} => {
	if (!hasUsedPromotion || !data.promotionId || !data.time)
		return {
			text: '00:00:00',
			expiresIn: 0,
		}

	// Parse the promotion date using your createDateFromString function
	const promotionStartDate = startDate
		? new Date(startDate)
		: createDateFromString(userEntity.hook.user[data.promotionId])

	// Calculate the promotion end date by adding the promotion duration
	const promotionEndDate: any = addDays(promotionStartDate, data.time / 24) // data.time is in hours, so divide by 24 to get days

	// Get the current time
	const now: any = new Date()

	// Calculate the difference in milliseconds
	const diffInMs = promotionEndDate - now

	// If the difference is negative (time has passed), return '00:00:00'
	if (diffInMs <= 0)
		return {
			text: '00:00:00',
			expiresIn: 0,
		}

	// Calculate the difference in days, hours, minutes, and seconds
	const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
	const hours = Math.floor(
		(diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
	)
	const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60))
	const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000)

	// Show different formats based on the time remaining:
	if (days > 0) {
		// More than 1 day left, show days and hours
		return {
			text: `${String(days).padStart(2, '0')}d ${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`,
			expiresIn: days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds,
		}
	} else if (hours > 0) {
		// Less than a day, show hours and minutes
		return {
			text: `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m`,
			expiresIn: hours * 60 * 60 + minutes * 60 + seconds,
		}
	} else {
		// Less than an hour, show minutes and seconds
		return {
			text: `${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`,
			expiresIn: minutes * 60 + seconds,
		}
	}
}
