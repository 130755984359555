import FortuneCard from '@/components/FortuneCard'
import { Button } from '@/components/ui/button'
import { useQuestionContext } from '@/contexts/QuestionContext'
import { CardsData0, CardsData1, CardsData2 } from '@/data/cards/CardsData'
import { ArrowLeft } from 'lucide-react'
import { useState } from 'react'
import { useHistory } from 'react-router'

export default function DetailsMobile() {
	const {
		hook: { currentQuestion },
	} = useQuestionContext()

	const navigate = useHistory()
	const [currentCard, setCurrentCard] = useState<number>(0)

	return (
		<div className="p-8 space-y-4">
			<div className="flex items-center gap-2">
				<Button
					onClick={() => navigate.replace('/')}
					className="text-white w-auto"
					variant="ghostLight"
					size="icon"
				>
					<ArrowLeft />
				</Button>
				<p className="text-white font-bold">Consulta completa</p>
			</div>

			<div>
				<p className="text-secondary font-bold">
					{currentQuestion?.question
						? currentQuestion?.question
						: 'Toque do Destino'}
				</p>
				<p className="text-white text-extraSmall">
					Realizado dia: {currentQuestion?.createdAt}
				</p>
			</div>

			<div className="space-y-4">
				<p className="text-secondary font-bold">Os Regentes:</p>
				<div className="flex items-center gap-4 w-full">
					<FortuneCard
						label
						cardNumber={currentQuestion?.numbers[0] || 0}
						cardSet={CardsData0}
						showingCard={currentCard > 0}
						currentCard={currentCard === 1}
						onClick={() => {
							if (currentCard === 1) setCurrentCard(0)
							else setCurrentCard(1)
						}}
					/>
					<FortuneCard
						label
						cardNumber={currentQuestion?.numbers[1] || 0}
						cardSet={CardsData1}
						showingCard={currentCard > 0}
						currentCard={currentCard === 2}
						onClick={() => {
							if (currentCard === 2) setCurrentCard(0)
							else setCurrentCard(2)
						}}
					/>
					<FortuneCard
						label
						cardNumber={currentQuestion?.numbers[2] || 0}
						cardSet={CardsData2}
						showingCard={currentCard > 0}
						currentCard={currentCard === 3}
						onClick={() => {
							if (currentCard === 3) setCurrentCard(0)
							else setCurrentCard(3)
						}}
					/>
				</div>
			</div>

			<div className="space-y-4">
				<p className="text-secondary text-small font-bold">Karma passivo</p>
				<p className="text-white text-extraSmall">
					{currentQuestion?.kassivo1}
					<br /> <br />
					{currentQuestion?.kassivo2}
					<br /> <br />
					{currentQuestion?.kassivo3}
				</p>
			</div>

			<div className="space-y-4">
				<p className="text-secondary text-small font-bold">Karma ativo</p>
				<p className="text-white text-extraSmall">
					{currentQuestion?.kativo4}
					<br /> <br />
					{currentQuestion?.kativo5}
					<br /> <br />
					{currentQuestion?.kativo6}
				</p>
			</div>

			<div className="space-y-4">
				<p className="text-secondary text-small font-bold">
					Interpretação livre
				</p>
				<div className="space-y-4">
					<p className="text-secondary text-small font-bold">Karma passivo</p>
					<div className="space-y-4 text-extraSmall text-white">
						<div className="flex flex-col gap-2">
							<p className="font-bold">{currentQuestion?.kassivo1}:</p>
							<p>{currentQuestion?.kassivo1Intepretation}</p>
						</div>
						<div className="flex flex-col gap-2">
							<p className="font-bold">{currentQuestion?.kassivo2}:</p>
							<p>{currentQuestion?.kassivo2Intepretation}</p>
						</div>
						<div className="flex flex-col gap-2">
							<p className="font-bold">{currentQuestion?.kassivo3}:</p>
							<p>{currentQuestion?.kassivo3Intepretation}</p>
						</div>
					</div>
				</div>
				<div className="space-y-4">
					<p className="text-secondary text-small font-bold">Karma ativo</p>
					<div className="space-y-4 text-white text-extraSmall">
						<div className="flex flex-col gap-2">
							<p className="font-bold">{currentQuestion?.kativo4}:</p>
							<p>{currentQuestion?.kativo4Intepretation}</p>
						</div>
						<div className="flex flex-col gap-2">
							<p className="font-bold">{currentQuestion?.kativo5}:</p>
							<p>{currentQuestion?.kativo5Intepretation}</p>
						</div>
						<div className="flex flex-col gap-2">
							<p className="font-bold">{currentQuestion?.kativo6}:</p>
							<p>{currentQuestion?.kativo6Intepretation}</p>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="space-y-4">
				<p className="text-secondary text-small font-bold">Números</p>
				<p className="text-white text-extraSmall">
					99 - 99 - 99 - 99 - 99 - 99
				</p>
			</div>

			<div className="space-y-4">
				<p className="text-secondary text-small font-bold">Sorte</p>
				<p className="text-white text-extraSmall">
					Lorem ipsum dolor sit amet,
				</p>
			</div> */}
		</div>
	)
}
