import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { ConsultationFormSchema, ConsultationSchema } from './schema'

export default function ConsultationForm() {
	const formMethods = useForm<ConsultationFormSchema>({
		resolver: zodResolver(ConsultationSchema),
	})

	return {
		formMethods,
	}
}
