import { App, URLOpenListenerEvent } from '@capacitor/app'
import { InAppBrowser } from '@capgo/inappbrowser'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const AppUrlListener: React.FC = () => {
	const history = useHistory()

	useEffect(() => {
		// Create a listener and clean it up properly
		const listenerPromise = App.addListener(
			'appUrlOpen',
			(event: URLOpenListenerEvent) => {
				// Extract the path from the URL
				const slug = new URL(event.url).pathname

				console.log('slug - ', slug)

				// Navigate to the correct route
				if (slug) {
					history.replace(slug)
				} else {
					history.replace('/')
				}

				InAppBrowser.close()
			},
		)

		// Cleanup the listener when the component unmounts
		listenerPromise.then((listener) => {
			return () => {
				listener.remove()
			}
		})

		// Return a cleanup function if listener is a function
		return () => {
			listenerPromise.then((listener) => listener.remove())
		}
	}, [history])

	useEffect(() => {
		InAppBrowser.addListener('urlChangeEvent', (event) => {
			if (event.url === 'https://fortune.prosperapps.com.br/home') {
				history.replace('/home')
				InAppBrowser.close()
			} else if (event.url === 'https://fortune.prosperapps.com.br/shop') {
				history.replace('/shop')
				InAppBrowser.close()
			}
		})
	}, [])

	return null
}

export default AppUrlListener
