import { exclude } from '@/firebase/auth'
import { ArrowRight } from 'lucide-react'
import { useState } from 'react'
import { Button } from '../ui/button'
import { Dialog, DialogContent } from '../ui/dialog'
import { Input } from '../ui/input'
import DeleteForm from './form/form'

export default function DeleteAccount({
	confirmation,
	setConfirmation,
}: {
	confirmation: boolean
	setConfirmation: (bool: boolean) => void
}) {
	const [confirmationStep, setConfirmationStep] = useState(1)

	const {
		formMethods: { register, watch },
	} = DeleteForm()

	return (
		<Dialog open={confirmation} onOpenChange={setConfirmation}>
			<DialogContent className="w-4/5 p-0 rounded-[10px] border-none">
				<div className="flex flex-col p-[.15rem]">
					{confirmationStep === 1 && (
						<>
							<div className="bg-primary-500 py-6 px-4 rounded-t-[10px] text-white text-[1.25rem] font-bold text-center">
								<p>Tem certeza que deseja excluir sua conta?</p>
							</div>
							<div className="flex items-center">
								<Button
									onClick={() => {
										setConfirmationStep(2)
									}}
									className="w-full h-full rounded-none rounded-bl-[10px] py-2 bg-red-300 text-white font-bold text-[1.25rem]"
								>
									Deletar
								</Button>
								<Button
									onClick={() => {
										setConfirmation(false)
									}}
									className="w-full h-full rounded-none rounded-br-[10px] text-primary font-bold text-[1.25rem]"
								>
									Cancelar
								</Button>
							</div>
						</>
					)}
					{confirmationStep === 2 && (
						<>
							<div className="bg-white py-2 px-4 rounded-t-[10px] text-red-700 text-[1.15rem] font-bold text-center">
								<p>Essa é uma ação irreversivel.</p>
							</div>
							<div className="flex flex-col gap-4 items-center bg-primary-500 rounded-b-[10px] justify-center py-4 px-2">
								<p className="text-white text-center font-bold text-[1.125rem]">
									Escreva "excluir conta" para confirmar a exclusão.
								</p>
								<div className="space-y-2">
									<div className="flex items-center gap-4 mx-2">
										<Input
											placeholder="EXCLUIR CONTA"
											type="text"
											{...register('input')}
											className="p-4 h-12 rounded-[10px] border-white placeholder:text-primary-300 bg-primary placeholder:font-bold placeholder:text-[1.125rem] text-[1.25rem] text-white"
										/>
										<Button
											onClick={async () => {
												await exclude()
												setConfirmation(false)
											}}
											disabled={watch('input') !== 'EXCLUIR CONTA'}
											className="bg-red-300 h-12 disabled:opacity"
										>
											<ArrowRight className="text-white" />
										</Button>
									</div>
									<p className="text-small text-center">
										Todas as suas moedas, brilho e registros serão apagados,{' '}
										<span className="text-red-300">
											sem chance de recuperação.
										</span>
									</p>
								</div>
								<Button
									onClick={() => {
										setConfirmation(false)
									}}
									variant="ghost"
									className="w-full text-white font-bold text-[1.25rem]"
								>
									Cancelar
								</Button>
							</div>
						</>
					)}
				</div>
			</DialogContent>
		</Dialog>
	)
}
