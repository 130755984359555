import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { SignUpFormSchema, SignUpSchema } from './schema'

export default function SignUpForm() {
	const formMethods = useForm<SignUpFormSchema>({
		resolver: zodResolver(SignUpSchema),
	})

	return {
		formMethods,
	}
}
