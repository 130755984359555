import { useState } from 'react'
import { IQuestion } from './interface/question'

export default function useQuestionHook() {
	//States
	const [questions, setQuestions] = useState<IQuestion[] | null>([])
	const [currentQuestion, setCurrentQuestion] = useState<IQuestion | null>()

	return {
		questions,
		setQuestions,
		currentQuestion,
		setCurrentQuestion,
	}
}
